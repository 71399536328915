import { useQuery } from '@apollo/react-hooks';
import SearchWalletTokens from '../../api/searchWalletTokens.query.gql';
import { GenverseTokenType } from '../../../types';
import { useEffect, useState } from 'react';

export default (address: string, searchQuery?: string | null) => {
    // @ts-ignore
    const [searchResults, setSearchResults] = useState(
        null as GenverseTokenType[] | null
    );

    /*
     *  Search tokens under wallet address (can be ENS as resolved by backend)
     * */
    const { data, loading, error, fetchMore } = useQuery(SearchWalletTokens, {
        variables: {
            address,
            searchQuery,
        },
        skip: !address?.length || !searchQuery || searchQuery?.length == 0,
    });

    useEffect(() => {
        if (!loading && !!data) {
            setSearchResults(data?.searchWalletTokens?.data?.results);
        }
    }, [loading, data]);

    const hasNextPage = !!data?.searchWalletTokens?.data?.cursor;
    const total = data?.searchWalletTokens?.data?.total;

    const loadNextPage = async () => {
        if (hasNextPage && !loading) {
            await fetchMore({
                variables: {
                    address,
                    // @ts-ignore
                    pathBuilder: () => {
                        const cursor = data?.searchWalletTokens?.data?.cursor;
                        return `/${address}/search?searchQuery=${searchQuery}&cursor=${JSON.stringify(
                            cursor.map((cursorKey: string) =>
                                typeof cursorKey === 'string'
                                    ? cursorKey.replace(/#/g, '%23')
                                    : cursorKey
                            )
                        )}`;
                    },
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult?.searchWalletTokens?.data)
                        return prevResult;

                    // Merge previous tokens & cursor
                    return {
                        searchWalletTokens: {
                            data: {
                                ...prevResult?.searchWalletTokens?.data,
                                cursor: fetchMoreResult?.searchWalletTokens
                                    ?.data?.cursor,
                                results: [
                                    ...prevResult?.searchWalletTokens?.data
                                        ?.results,
                                    ...fetchMoreResult?.searchWalletTokens?.data
                                        ?.results,
                                ],
                            },
                        },
                    };
                },
            });
        }
    };

    return {
        searchQueryText: searchQuery,
        total,
        searchResults,
        loading,
        error,
        hasNextPage,
        loadNextPage,
    };
};
