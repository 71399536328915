import { useQuery } from '@apollo/react-hooks';
import SearchPageQuery from '../../api/searchGenversePage.query.gql';
import SearchPageByCollectionQuery from '../../api/searchGenversePageByCollection.query.gql';
import { GenversePageDataType } from '../../../types';
import { useMoralis } from 'react-moralis';
import { useEffect, useState } from 'react';
import {
    BY_COLLECTION,
    BY_NFT,
} from '../components/MetaPage/core/WalletNFTGrid';

export default ({
    address,
    groupBy,
}: {
    address: string;
    groupBy?: null | 'BY_NFT' | 'BY_COLLECTION';
}) => {
    const { isAuthenticated, user } = useMoralis();

    const currentUserAddress = user?.get('ethAddress');
    const [isCurrentUser, setIsCurrentUser] = useState(false);
    const [groupByState, setGroupByState] = useState(groupBy);

    useEffect(() => {
        setGroupByState(groupBy);
    }, [groupBy]);

    /*
     *  Standard ungrouped NFTs data
     * */
    const { data, loading, error, fetchMore } = useQuery(SearchPageQuery, {
        variables: {
            address,
        },
        skip: !address?.length,
    });

    /*
     *  Standard grouped NFTs data by collection
     * */
    const {
        data: byCollectionData,
        loading: byCollectionLoading,
        error: byCollectionError,
    } = useQuery(SearchPageByCollectionQuery, {
        variables: {
            address,
            groupBy: groupByState,
        },
        skip: !address?.length || groupByState !== BY_COLLECTION,
    });

    // @ts-ignore
    const [genversePage, setGenversePage] = useState(
        null as GenversePageDataType | null
    );

    const hasNextPage = !!genversePage?.cursor;

    useEffect(() => {
        // First load only
        if ((!groupByState || groupByState === BY_NFT) && !loading && !!data) {
            const pageData = data?.searchGenversePage?.data;
            // setTokens(pageData.tokens);
            setGenversePage(pageData);
        }

        if (
            groupByState === BY_COLLECTION &&
            !byCollectionLoading &&
            !!byCollectionData
        ) {
            const pageData =
                byCollectionData?.searchGenversePageByCollection?.data;
            // setTokens(pageData.tokens);
            setGenversePage(pageData);
        }
    }, [loading, data, byCollectionData, byCollectionLoading, groupByState]);

    const loadNextPage = async () => {
        if (hasNextPage && !loading) {
            await fetchMore({
                variables: {
                    address,
                    // @ts-ignore
                    pathBuilder: () => {
                        const cursor = genversePage?.cursor;
                        return (
                            cursor?.ownerOf
                                ? `/${address}/cursor1/${cursor.PK}/${cursor.ownerOf}/`
                                : `/${address}/cursor2/${cursor.PK}/${cursor.moralisUserId}/`
                        ).replace(/#/g, '%23');
                    },
                    ...genversePage?.cursor, // Apply cursor if any
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult?.searchGenversePage?.data)
                        return prevResult;

                    // Merge previous tokens & cursor
                    return {
                        searchGenversePage: {
                            data: {
                                ...prevResult?.searchGenversePage?.data,
                                cursor: fetchMoreResult?.searchGenversePage
                                    ?.data?.cursor,
                                tokens: [
                                    ...prevResult?.searchGenversePage?.data
                                        ?.tokens,
                                    ...fetchMoreResult?.searchGenversePage?.data
                                        ?.tokens,
                                ],
                            },
                        },
                    };
                },
            });
        }
    };

    // Keep isCurrentUser in sync
    useEffect(() => {
        if (isAuthenticated) {
            setIsCurrentUser(
                currentUserAddress === genversePage?.address?.toLowerCase()
            );
        }
    }, [isAuthenticated, user, genversePage]);

    return {
        genversePage,
        isCurrentUser,
        loading: loading || byCollectionLoading,
        error: error || byCollectionError,
        hasNextPage,
        loadNextPage,
    } as {
        genversePage: GenversePageDataType;
        isCurrentUser: boolean;
        loading: boolean;
        error: any;
        hasNextPage: boolean;
        loadNextPage: () => void;
    };
};
