import { Button, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { ButtonProps } from '@chakra-ui/button/dist/declarations/src/button';

export default (props: ButtonProps) => {
    const { children, ...restProps } = props;

    return (
        <Button
            color={
                props?.variant === 'outline'
                    ? useColorModeValue('black', 'white')
                    : useColorModeValue('white', 'black')
            }
            {...(props?.variant === 'outline'
                ? {
                      borderColor: useColorModeValue(
                          'rgba(0, 0, 0, 0.5)',
                          'rgba(255, 255, 255, 0.5)'
                      ),
                  }
                : { backgroundColor: useColorModeValue('black', 'white') })}
            {...restProps}
        >
            {children}
        </Button>
    );
};
