import {
    AspectRatio,
    Box,
    Flex,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { GenverseTokenType } from '../../../../../types';
import NFTImage from '../NFTImage';
import { parseInt, truncate } from 'lodash';
import Tilt from 'react-parallax-tilt';
import { BsThreeDots } from 'react-icons/bs';

// const ENS_CONTRACT_ADDRESS = '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85';

export default ({
    token,
    disableTilt,
    onClickQuickView,
    isCurrentUser,
}: {
    token: GenverseTokenType;
    disableTilt?: boolean;
    onClickQuickView?: () => void;
    isCurrentUser?: boolean;
}) => {
    const [genverseToken, setGenverseToken] = useState(token);
    useEffect(() => {
        setGenverseToken(token);
    }, [token]);
    const [scale] = useState(1.01);
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    // let subText = `#${truncate(token?.tokenId, {
    //     length: 20,
    //     omission: '...',
    // })}`;

    const TiltWrapper = useMemo(
        () =>
            ({ children }: { children: React.ReactNode }) =>
                disableTilt ? (
                    <>{children}</>
                ) : (
                    <Tilt
                        scale={scale}
                        // @ts-ignore
                        key={genverseToken}
                    >
                        {children}
                    </Tilt>
                ),
        [disableTilt]
    );

    const textTruncLength = isMobile ? 20 : 30;
    const textFontSize = ['0.75rem', '0.75rem'];

    return (
        <TiltWrapper>
            <AspectRatio maxW="100%" ratio={14 / 20}>
                <Box w={'100%'} h={'100%'} cursor="pointer">
                    <Flex
                        w={'100%'}
                        h={'100%'}
                        // border="solid 1px rgba(255, 255, 255, 0.2)"
                        background={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'rgba(218, 175, 255, 0.05)'
                        )}
                        backdropFilter={useColorModeValue(
                            'brightness(100%)',
                            'brightness(200%)'
                        )}
                        borderRadius="0.375rem"
                        overflow="hidden"
                        direction="column"
                        justify="space-between"
                    >
                        <AspectRatio
                            maxW="100%"
                            ratio={1}
                            key={`${genverseToken?.chain}#${genverseToken?.tokenAddress}#${genverseToken?.tokenId}`}
                        >
                            <NFTImage
                                key={`${genverseToken?.chain}#${genverseToken?.tokenAddress}#${genverseToken?.tokenId}`}
                                token={genverseToken}
                                // w="100%"
                                alt={genverseToken?.metadata?.name}
                                // preference="image"
                            />
                        </AspectRatio>
                        {/*{!!onClickQuickView && (*/}
                        {/*    <Flex*/}
                        {/*        display={['none', 'flex']}*/}
                        {/*        padding=".5rem .5rem 0 .5rem"*/}
                        {/*        align="center"*/}
                        {/*        justify="flex-end"*/}
                        {/*        width="100%"*/}
                        {/*    >*/}
                        {/*        <Box*/}
                        {/*            onClick={(e) => {*/}
                        {/*                if (e?.stopPropagation)*/}
                        {/*                    e.stopPropagation();*/}
                        {/*                onClickQuickView();*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <AiFillEye size={20} />*/}
                        {/*        </Box>*/}
                        {/*    </Flex>*/}
                        {/*)}*/}
                        <Flex
                            flex={1}
                            direction="column"
                            justify="center"
                            align="center"
                            padding={['0.1rem']}
                            position="relative"
                        >
                            {isCurrentUser && (
                                <BsThreeDots
                                    style={{
                                        position: 'absolute',
                                        top: '.5rem',
                                        right: '.5rem',
                                    }}
                                />
                            )}
                            <Text
                                textAlign="center"
                                fontSize={textFontSize}
                                marginBottom={'0.2rem'}
                                opacity={0.7}
                            >
                                {`${truncate(genverseToken?.contract?.name, {
                                    length: textTruncLength,
                                    omission: '...',
                                })}${
                                    parseInt(genverseToken?.amount || '1') > 1
                                        ? ` x${genverseToken.amount}`
                                        : ''
                                }`}
                            </Text>
                            {(!!genverseToken?.ensData?.name ||
                                !!genverseToken?.metadata?.name?.length) && (
                                <Text
                                    textAlign="center"
                                    fontSize={textFontSize}
                                    marginBottom={'0.2rem'}
                                    fontWeight={900}
                                >
                                    {truncate(
                                        genverseToken?.ensData?.name ||
                                            genverseToken?.metadata?.name,
                                        {
                                            length: textTruncLength,
                                            omission: '...',
                                        }
                                    )}
                                </Text>
                            )}
                            {!!genverseToken?.rarityRank && (
                                <Text
                                    textAlign="center"
                                    fontWeight={'800'}
                                    opacity={'0.7'}
                                    fontSize={'0.7rem'}
                                >
                                    {`#${genverseToken.rarityRank || ''} / ${
                                        genverseToken?.rarityRankBaseCount || ''
                                    } (Top ${
                                        genverseToken?.rarityTopPercent?.toFixed(
                                            0
                                        ) || ''
                                    }%)`}
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                </Box>
            </AspectRatio>
        </TiltWrapper>
    );
};
