import {
    Box,
    Button,
    Flex,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useColorModeValue,
    useDisclosure,
    useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';
import {
    MenuButtonProps,
    MenuItemProps,
    MenuListProps,
} from '@chakra-ui/menu/dist/declarations/src/menu';
import { MenuProps } from '@chakra-ui/menu/src/menu';

export default ({
    menuItems,
    menuProps,
    menuButtonProps,
    menuListProps,
    menuItemProps,
    showChevronDown = true,
    children,
}: {
    menuItems: {
        label: string;
        icon?: any;
        onClick: () => void;
    }[];
    menuProps?: MenuProps;
    menuButtonProps?: MenuButtonProps;
    menuListProps?: MenuListProps;
    menuItemProps?: MenuItemProps;
    showChevronDown?: boolean;
    children?: React.ReactNode;
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    return (
        <Menu isOpen={isOpen} {...menuProps}>
            <MenuButton
                as={Button}
                color={useColorModeValue('black', 'white')}
                background='transparent'
                {...(showChevronDown
                    ? {
                          rightIcon: (
                              <BiChevronDown
                                  color={useColorModeValue('black', 'white')}
                              />
                          ),
                      }
                    : {})}
                margin={'1rem 0 .5rem 0'}
                fontWeight="bold"
                fontSize="1.3rem"
                textAlign="center"
                _active={{ bg: 'rgba(255, 255, 255, 0.05)' }}
                _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}
                _expanded={{ bg: 'rgba(0, 0, 0, 0.5))' }}
                _focus={{ boxShadow: 'outline' }}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                {...(isMobile
                    ? {
                          onClick: isOpen ? onClose : onOpen,
                      }
                    : {
                          onMouseEnter: onOpen,
                          onMouseLeave: onClose,
                      })}
                {...menuButtonProps}
            >
                {children}
            </MenuButton>
            <MenuList
                color={useColorModeValue('black', 'white')}
                background={useColorModeValue(
                    'rgba(255, 255, 255, 0.9)',
                    'rgba(0, 0, 0, 0.9)'
                )}
                border={useColorModeValue(
                    'solid 1px rgba(0, 0, 0, .2)',
                    'solid 1px rgba(255, 255, 255, .2)'
                )}
                {...(isMobile
                    ? {}
                    : {
                          onMouseEnter: onOpen,
                          onMouseLeave: onClose,
                      })}
                {...menuListProps}
            >
                {menuItems.map(({ label, onClick, icon }) => (
                    <MenuItem
                        _active={{ bg: 'rgba(255, 255, 255, 0.05)' }}
                        _hover={{ bg: 'rgba(255, 255, 255, 0.1)' }}
                        _expanded={{ bg: 'rgba(0, 0, 0, 0.5))' }}
                        _focus={{ boxShadow: 'outline' }}
                        onClick={onClick}
                        {...menuItemProps}
                    >
                        <Flex align="center">
                            {icon && <Box marginRight=".5rem">{icon}</Box>}
                            <Box>{label}</Box>
                        </Flex>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};
