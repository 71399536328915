import React, { useEffect, useState } from 'react';
import { Flex, Input, Text, useColorModeValue } from '@chakra-ui/react';
import { InputProps } from '@chakra-ui/input/dist/declarations/src/input';
import { ButtonProps } from '@chakra-ui/button/dist/declarations/src/button';
import useGenverseSearchWalletTokens from '../../hooks/useGenverseSearchWalletTokens';
import {
    GenverseTokenType,
    SearchWalletResultsDataType,
} from '../../../../types';
import { StringParam, useQueryParams } from 'use-query-params';
import G3Button from '../general/G3Button';
import { FlexProps } from '@chakra-ui/layout/dist/declarations/src/flex';

export default ({
    walletAddress,
    inputProps,
    buttonProps,
    onSearchResults,
    useURLQueryParams,
    containerProps,
}: {
    walletAddress: string;
    inputProps?: InputProps;
    buttonProps?: ButtonProps;
    onSearchResults: (searchResultData: SearchWalletResultsDataType) => void;
    useURLQueryParams?: boolean;
    containerProps?: FlexProps;
}) => {
    const [queryParams, setQueryParams] = useQueryParams({
        searchQuery: StringParam,
    });

    const [searchQueryText, setSearchQueryText] = useState(
        useURLQueryParams ? queryParams?.searchQuery : ''
    );

    // If not using query params we use this local state to hold searchQuery
    const [currentSearchQuery, setCurrentSearchQuery] = useState(
        null as string | null | undefined
    ); // null = no search requested

    const { total, searchResults, loading, loadNextPage, hasNextPage } =
        useGenverseSearchWalletTokens(
            walletAddress,
            useURLQueryParams ? queryParams?.searchQuery : currentSearchQuery
        );

    const onSearch = async () => {
        if (loading) return;
        if (useURLQueryParams) {
            setQueryParams({ searchQuery: searchQueryText });

            if (searchQueryText?.length === 0) {
                setQueryParams({ searchQuery: undefined });
            }
        } else {
            setCurrentSearchQuery(
                searchQueryText?.length === 0 ? null : searchQueryText
            );
        }
    };

    useEffect(() => {
        onSearchResults({
            searchQueryText: searchQueryText as string,
            loading,
            searchResults: searchResults as GenverseTokenType[],
            hasNextPage,
            loadNextPage,
            total,
        });
    }, [searchResults, loading, hasNextPage, total]);

    useEffect(() => {
        if (
            (useURLQueryParams &&
                (!queryParams?.searchQuery ||
                    queryParams?.searchQuery?.length == 0)) ||
            (!useURLQueryParams &&
                (!currentSearchQuery || currentSearchQuery?.length == 0))
        ) {
            onSearchResults({
                searchQueryText: searchQueryText as string,
                loading: false,
                searchResults: null,
                hasNextPage: false,
                loadNextPage,
                total,
            });
        }
    }, [queryParams?.searchQuery, currentSearchQuery]);

    return (
        <Flex direction="column" {...containerProps}>
            <Flex
                flex={1}
                width="100%"
                border={useColorModeValue(
                    'solid 1px rgba(0, 0, 0, .2)',
                    'solid 1px rgba(255, 255, 255, .2)'
                )}
                padding=".5rem"
                align="center"
                borderRadius="0.375rem"
            >
                <Input
                    size="sm"
                    placeholder="ape, owl, bird?"
                    border={0}
                    flex={1}
                    value={searchQueryText || ''}
                    onChange={(e) => setSearchQueryText(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') onSearch();
                    }}
                    autoCapitalize="none"
                    autoComplete="none"
                    {...inputProps}
                />
                <G3Button
                    size="sm"
                    marginLeft=".5rem"
                    onClick={onSearch}
                    {...buttonProps}
                >
                    Search NFTs
                </G3Button>
            </Flex>
            {!!total && (
                <Flex marginTop="1rem">
                    <Text color="tertiary">{`Found ${total} NFTs`}</Text>
                </Flex>
            )}
        </Flex>
    );
};
