import React from 'react';
import { Text, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'gatsby';
import useENSName from '../../../hooks/useENSName';
import shortenAddress from '../../../utils/shortenAddress';

export default ({
    walletAddress,
    prefix,
    suffix,
    shortAddress,
    styles,
    isLink = true,
}: {
    walletAddress: string;
    prefix?: string;
    suffix?: string;
    shortAddress?: boolean;
    styles?: any;
    isLink?: boolean;
}) => {
    if (!walletAddress) return <></>;
    const { ensName, address } = useENSName(walletAddress);
    if (!address) return <>...</>;

    let formattedAddress = address;
    if (shortAddress) formattedAddress = shortenAddress(address);

    // formattedAddress = formattedAddress || '...'; // ... when loading

    const Wrapper = ({
        children,
        to,
    }: {
        children: React.ReactNode;
        to: string;
    }) => (isLink ? <Link to={to}>{children}</Link> : <>{children}</>);

    return (
        <Wrapper to={`/page/${ensName || address || walletAddress}`}>
            <Text
                size="md"
                textAlign={['center', 'left']}
                fontWeight="300"
                color={useColorModeValue('black', 'white')}
                marginTop=".5rem"
                {...styles}
            >
                {`${prefix || ''}${
                    ensName || formattedAddress || walletAddress
                }${suffix || ''}`}
            </Text>
        </Wrapper>
    );
};
