import React from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react';
import './style.css';
import {
    GenverseContractAttrRarityType,
    GenverseTokenType,
} from '../../../../../types';
import useGenverseContract from '../../../hooks/useGenverseContract';
import NFTImage from '../NFTImage';
import G3LogoSpinner from '../G3LogoSpinner';
import { GLOW_BACKGROUNDS } from '../../../consts/THEME_OPTIONS';

export default ({
    showModal,
    token,
    onClose,
}: {
    token: GenverseTokenType;
    showModal: null | boolean;
    onClose: () => void;
}) => {
    if (!showModal || !token) return <></>;

    const { genverseContract, loading } = useGenverseContract({
        chain: token?.chain,
        contractAddress: token?.tokenAddress,
    });

    const getTokenTrait = (traitName: string) =>
        token?.metadata?.attributes?.find(
            ({ traitType }: { traitType: string }) => traitType === traitName
        );

    const tokenTraitCountOwners =
        // @ts-ignore
        token?.traitsCount?.count > 0 &&
        genverseContract?.traitRarity?.traitsCountDistribution?.[
            // @ts-ignore
            token?.traitsCount?.count
        ];

    return (
        <Modal
            isOpen={!!showModal}
            isCentered
            scrollBehavior="inside"
            // closeIcon={<CloseIcon w={4} h={4} color="primary" />}
            onClose={onClose}
        >
            <ModalOverlay backgroundColor={'rgba(0, 0, 0, 0.9);'} />
            <ModalContent
                background={useColorModeValue('white', 'black')}
                backgroundImage={GLOW_BACKGROUNDS.V1_PURPLE.image}
                border="solid 1px rgba(255, 255, 255, 0.15)"
                overflowY="scroll"
                w={['90vw', '65vw']}
                maxW="auto"
            >
                <ModalHeader>
                    <Heading
                        size="md"
                        textAlign={['center', 'left']}
                        marginBottom=".5rem"
                    >
                        {`Rarity Calculation`}
                    </Heading>
                    <Heading
                        size="sm"
                        textAlign={['center', 'left']}
                        fontWeight="300"
                        marginBottom="1rem"
                    >
                        How we calculate NFT rarity ranks
                    </Heading>
                </ModalHeader>
                <ModalBody>
                    <Text>
                        Every NFT rarity insight service (Rarity Tools, Trait
                        Sniper, SuperSea, etc.) has their own formulas and thus
                        produce different results.
                    </Text>
                    <Text margin="1rem 0">
                        Below is the complete, transparent explanation on how we
                        calculate NFT rarity using a simple formula.
                    </Text>
                    <Text>Let's use this token as an example:</Text>
                    <Box marginTop="2rem">
                        <Flex direction="row" align="center">
                            <Box width="5rem">
                                <NFTImage
                                    token={token}
                                    alt={token?.metadata?.name}
                                    imageProps={{
                                        objectPosition: 'center',
                                        borderRadius: '0.375rem',
                                    }}
                                />
                            </Box>
                            <Flex
                                flex={1}
                                direction="column"
                                marginTop=".5rem"
                                marginLeft={'1rem'}
                            >
                                <Text fontWeight="800">{`${
                                    token?.contract?.name ||
                                    token?.metadata?.name
                                } (Token ID: ${token?.tokenId})`}</Text>
                                <Flex
                                    direction="row"
                                    align="center"
                                    justify="flex-start"
                                >
                                    <Text textAlign="center">
                                        {token?.rarityRank
                                            ? `Rarity Rank:`
                                            : ''}
                                    </Text>
                                    <Text
                                        fontWeight="600"
                                        marginLeft="1rem"
                                        textAlign="center"
                                        color={useColorModeValue(
                                            'black',
                                            'white'
                                        )}
                                    >
                                        {token?.rarityRank
                                            ? `${
                                                  token?.rarityRank
                                              } within ${token?.rarityRankBaseCount?.toLocaleString()} collection NFTs`
                                            : ''}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Box
                            marginTop="1rem"
                            background={useColorModeValue(
                                'rgba(0, 0, 0, 0.05)',
                                'rgba(255, 255, 255, 0.05)'
                            )}
                            borderRadius="0.375rem"
                            padding=".5rem 1rem 1rem 1rem"
                        >
                            <Flex align="center">
                                <Text fontSize={['.7rem', '1rem']}>
                                    {`Rarity Percentage`}
                                </Text>
                                <Text fontSize="2rem" margin="0 1rem">
                                    =
                                </Text>
                                <Text fontSize={['.7rem', '1rem']}>
                                    {`Total Tokens With Specific Trait / Total Collection Tokens (${token?.rarityRankBaseCount?.toLocaleString()})`}
                                </Text>
                            </Flex>

                            <Flex align="center">
                                <Text
                                    fontSize={['.7rem', '1rem']}
                                >{`Rarity Score`}</Text>
                                <Text fontSize="2rem" margin="0 1rem">
                                    =
                                </Text>
                                <Text fontSize={['.7rem', '1rem']}>
                                    {`1 / Rarity Percentage`}
                                </Text>
                            </Flex>

                            <Text
                                fontSize="1rem"
                                marginTop="1rem"
                            >{`We divide 1 by the Rarity Percentage to create a "non-linear inverse relationship" between Rarity Percentage and Rarity Score which enables larger distribution between very rare and very common tokens. This method is used by many of the most popular rarity services.`}</Text>

                            <Text marginTop="1rem" fontSize="1rem">
                                {`The Rarity Score is then sorted with the highest being the most rare to produce the rank number.`}
                            </Text>
                        </Box>

                        {loading && (
                            <G3LogoSpinner
                                containerProps={{
                                    h: '20vh',
                                }}
                            />
                        )}
                        {!loading && !!genverseContract && (
                            <>
                                <Text fontSize="1rem" marginTop="2rem">
                                    Calculation:
                                </Text>
                                <Table
                                    size="lg"
                                    variant="unstyled"
                                    marginTop="1rem"
                                >
                                    <Thead>
                                        <Tr>
                                            {[
                                                { label: 'Trait' },
                                                { label: 'This NFT' },
                                                { label: 'Owners with trait' },
                                                { label: 'Rarity Percentage*' },
                                                { label: 'Rarity Score*' },
                                            ].map(
                                                ({
                                                    label,
                                                }: {
                                                    label: string;
                                                }) => (
                                                    <Th
                                                        // key={`td-${traitType}-${index}`}
                                                        textAlign={[
                                                            'center',
                                                            'left',
                                                        ]}
                                                        color={useColorModeValue(
                                                            'rgba(0, 0, 0, 0.5)',
                                                            'rgba(255, 255, 255, 0.5)'
                                                        )}
                                                        fontSize="1rem"
                                                        padding="1rem"
                                                        textTransform="unset"
                                                    >
                                                        {label}
                                                    </Th>
                                                )
                                            )}
                                        </Tr>
                                    </Thead>
                                    <Tbody marginBottom="1rem">
                                        {genverseContract?.traitRarity?.attributesRarity?.map(
                                            (
                                                {
                                                    name,
                                                    traitCounts,
                                                }: GenverseContractAttrRarityType,
                                                keyIndex: number
                                            ) => {
                                                const tokenTrait =
                                                    getTokenTrait(name);
                                                const isEmptyTrait =
                                                    !tokenTrait ||
                                                    !tokenTrait?.value;

                                                const attributeRarity =
                                                    traitCounts?.find(
                                                        ({
                                                            name,
                                                        }: {
                                                            name: string;
                                                        }) =>
                                                            isEmptyTrait
                                                                ? name ===
                                                                  '<empty_trait>'
                                                                : name ===
                                                                  tokenTrait?.value
                                                    );

                                                return (
                                                    <Tr
                                                        background={
                                                            keyIndex % 2 === 0
                                                                ? useColorModeValue(
                                                                      'rgba(0, 0, 0, 0.1)',
                                                                      'rgba(0, 0, 0, 0.3)'
                                                                  )
                                                                : 'transparent'
                                                        }
                                                    >
                                                        {[
                                                            name,
                                                            tokenTrait?.value ||
                                                                '<none>',
                                                            attributeRarity?.count,
                                                            attributeRarity?.rarityPercentage?.toFixed(
                                                                2
                                                            ),
                                                            attributeRarity?.inverseRarityScore?.toFixed(
                                                                2
                                                            ),
                                                        ].map((data) => (
                                                            <Td
                                                                textAlign={[
                                                                    'center',
                                                                    'left',
                                                                ]}
                                                                color={useColorModeValue(
                                                                    'black',
                                                                    'white'
                                                                )}
                                                                fontSize="1rem"
                                                                padding="1rem"
                                                            >
                                                                {data}
                                                            </Td>
                                                        ))}
                                                    </Tr>
                                                );
                                            }
                                        )}
                                        <Tr
                                            borderTop={useColorModeValue(
                                                'solid 1px rgba(0, 0, 0, 0.5)',
                                                'solid 1px rgba(255, 255, 255, 0.5)'
                                            )}
                                        >
                                            <Td
                                                textAlign={['center', 'left']}
                                                color={useColorModeValue(
                                                    'black',
                                                    'white'
                                                )}
                                                fontSize="1rem"
                                                fontWeight="bold"
                                                padding="1rem"
                                            >
                                                Trait Count
                                            </Td>
                                            <Td
                                                textAlign={['center', 'left']}
                                                fontSize="1rem"
                                                fontWeight="bold"
                                                padding="1rem"
                                            >
                                                {token?.traitsCount?.count}
                                            </Td>
                                            <Td
                                                textAlign={['center', 'left']}
                                                fontSize="1rem"
                                                fontWeight="bold"
                                                padding="1rem"
                                            >
                                                {tokenTraitCountOwners}
                                            </Td>
                                            <Td
                                                textAlign={['center', 'left']}
                                                fontSize="1rem"
                                                fontWeight="bold"
                                                padding="1rem"
                                            >{`${token?.traitsCount?.rarityPercentage?.toFixed(
                                                2
                                            )}%`}</Td>
                                            <Td
                                                textAlign={['center', 'left']}
                                                fontSize="1rem"
                                                fontWeight="bold"
                                                padding="1rem"
                                            >{`${token?.traitsCount?.rarityScore?.toFixed(
                                                2
                                            )}`}</Td>
                                        </Tr>
                                        <Tr
                                            marginTop="2rem"
                                            borderTop={useColorModeValue(
                                                'solid 1px rgba(0, 0, 0, 0.5)',
                                                'solid 1px rgba(255, 255, 255, 0.5)'
                                            )}
                                        >
                                            <Td
                                                textAlign={['center', 'left']}
                                                color={useColorModeValue(
                                                    'black',
                                                    'white'
                                                )}
                                                fontSize="1rem"
                                                fontWeight="bold"
                                                padding="1rem"
                                            >
                                                Total Rarity Score
                                            </Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td
                                                textAlign={['center', 'left']}
                                                fontSize="1rem"
                                                fontWeight="bold"
                                                padding="1rem"
                                            >
                                                {token.totalRarityScore?.toFixed(
                                                    2
                                                )}
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </>
                        )}
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button size="md" background="secondary" onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
