import React, { useEffect, useRef, useState } from 'react';
import {
    AspectRatio,
    Flex,
    Image,
    ScaleFade,
    useColorModeValue,
} from '@chakra-ui/react';
import LazyLoad from 'react-lazyload';
import { GenverseTokenType } from '../../../../../types';
import getTokenMetadataImage from '../../../utils/getTokenMetadataImage';
import getTokenMetadataVideo from '../../../utils/getTokenMetadataVideo';
import G3LogoDark from '../../../../assets/g3-dark-logo-icon.svg';
import G3LogoSpinner from '../G3LogoSpinner';
import G3LogoLight from '../../../../assets/g3-light-logo-icon.svg';
// const isImageExtension = (url: string) => {
//     return url?.toLowerCase()?.match(/\.(jpeg|jpg|gif|png)$/) != null;
// };

export default ({
    token,
    width = '100%',
    height = '100%',
    borderRadius = '0',
    border,
    margin = '0',
    alt,
    onLoadUpdate,
    onClick,
    imageProps,
    preference,
}: {
    token: GenverseTokenType;
    width?: string;
    height?: string;
    borderRadius?: string;
    border?: string;
    margin?: string;
    onLoadUpdate?: (loading: boolean) => void;
    alt: string;
    onClick?: () => void;
    imageProps?: any;
    preference?: null | 'image' | 'video';
}) => {
    const ref = useRef<any>(null);
    const [imageWidth, setImageWidth] = useState(0);

    useEffect(() => {
        if (ref.current?.offsetWidth > 0 && !imageWidth) {
            // Rounding up to nearest 100
            setImageWidth(Math.ceil(ref.current.offsetWidth / 100) * 100);
        }
    }, [ref?.current, imageWidth]);

    const [sources, setSources] = useState(
        {} as { imageSrc: string; videoSrc?: string }
    );

    useEffect(() => {
        if (token) {
            const imageSrc = getTokenMetadataImage(token, imageWidth);
            const videoSrc =
                preference === 'image'
                    ? undefined
                    : getTokenMetadataVideo(token, imageWidth);

            setSources({ imageSrc, videoSrc });
        }
    }, [token, imageWidth, preference]);

    const [errors, setErrors] = useState([] as string[]);
    const addError = (newError: string) =>
        setErrors((prevErrors) => [...prevErrors, newError]);
    const [loading, setLoading] = useState(true);

    const [isVideo, setIsVideo] = useState(false);
    const [src, setSrc] = useState('');

    useEffect(() => {
        if (!errors?.length) return;

        // If video failed try using it as an image
        if (isVideo && errors.indexOf('video') > -1 && errors?.length === 1) {
            setIsVideo(false);
            setSrc(sources?.imageSrc);
            // setErrors([]);
        }

        // If image failed try using it as an video
        if (!isVideo && errors.indexOf('image') > -1 && errors?.length === 1) {
            // Some images are video links :shrug:

            setIsVideo(true);
            setSrc(sources?.imageSrc);
        }
    }, [errors]);

    useEffect(() => {
        (async () => {
            if (sources?.videoSrc) {
                setIsVideo(true);
                setSrc(sources?.videoSrc);
            } else {
                setIsVideo(false);
                setSrc(sources?.imageSrc);
            }
        })();
    }, [sources?.imageSrc, sources?.videoSrc]);

    useEffect(() => {
        if (!loading && !!onLoadUpdate) {
            onLoadUpdate(loading);
        }
    }, [loading]);

    return (
        <>
            {/* Image and video loading failed, this is the error state */}
            {(!src || (errors?.length === 2 && !loading)) && (
                <Flex
                    w="100%"
                    h={'100%'}
                    justify="center"
                    align="center"
                    opacity="0.2"
                    ref={ref}
                >
                    <Image
                        src={useColorModeValue(G3LogoLight, G3LogoDark)}
                        width="2rem"
                    />
                </Flex>
            )}

            {/* Loading spinner */}
            {!!src && errors?.length !== 2 && loading && (
                <G3LogoSpinner
                    containerProps={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                    }}
                />
            )}

            {/* Only displaying if both image and videos have not been tried (errors < 2) */}
            {errors?.length !== 2 && !!src && (
                <AspectRatio
                    maxW="100%"
                    ratio={1}
                    w="100%"
                    borderRadius={borderRadius}
                    transform="translateZ(0)"
                    border={border}
                    margin={margin}
                    overflow="hidden"
                    onClick={onClick}
                >
                    <LazyLoad
                        once
                        key={`${token.chain}#${token.tokenAddress}#${token.tokenId}`}
                    >
                        <ScaleFade in={!loading} style={{ height: '100%' }}>
                            {isVideo && (
                                <video
                                    key={src}
                                    height={height}
                                    width={width}
                                    controls={false}
                                    autoPlay
                                    muted
                                    loop
                                    onError={() => addError('video')}
                                    onLoadedData={() => setLoading(false)}
                                    playsInline
                                >
                                    <source src={src} />
                                </video>
                            )}
                            {!isVideo && (
                                <Image
                                    key={src}
                                    height={height}
                                    width={width}
                                    objectPosition="center"
                                    objectFit="contain"
                                    border={0}
                                    borderRadius={borderRadius}
                                    src={src || ''}
                                    alt={alt}
                                    onError={(e) => addError('image')}
                                    onLoad={() => setLoading(false)}
                                    loading="lazy"
                                    {...imageProps}
                                />
                            )}
                        </ScaleFade>
                    </LazyLoad>
                </AspectRatio>
            )}
        </>
    );
};
