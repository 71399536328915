import { AlchemyWeb3, createAlchemyWeb3 } from '@alch/alchemy-web3';

const providers = {
    dev: {
        eth: {
            websocket:
                'wss://eth-mainnet.alchemyapi.io/v2/SvQdCXqVapYPfoOQ7qXioSNi_xm6R663',
            http: 'https://eth-mainnet.alchemyapi.io/v2/SvQdCXqVapYPfoOQ7qXioSNi_xm6R663',
        },
        polygon: {
            websocket:
                'wss://polygon-mainnet.g.alchemy.com/v2/NH3s-GYa6OE_DgthjAnGzedw5oeao7MF',
            http: 'https://polygon-mainnet.g.alchemy.com/v2/NH3s-GYa6OE_DgthjAnGzedw5oeao7MF',
        },
        rinkeby: {
            websocket:
                'wss://eth-rinkeby.alchemyapi.io/v2/OLzklTRhXgHUl-XAAlqGmMpvWbogPYgM',
            http: 'https://eth-rinkeby.alchemyapi.io/v2/OLzklTRhXgHUl-XAAlqGmMpvWbogPYgM',
        },
    },
    prod: {
        eth: {
            websocket:
                'wss://eth-mainnet.alchemyapi.io/v2/SvQdCXqVapYPfoOQ7qXioSNi_xm6R663',
            http: 'https://eth-mainnet.alchemyapi.io/v2/SvQdCXqVapYPfoOQ7qXioSNi_xm6R663',
        },
        polygon: {
            websocket:
                'wss://polygon-mainnet.g.alchemy.com/v2/NH3s-GYa6OE_DgthjAnGzedw5oeao7MF',
            http: 'https://polygon-mainnet.g.alchemy.com/v2/NH3s-GYa6OE_DgthjAnGzedw5oeao7MF',
        },
        rinkeby: {
            websocket:
                'wss://eth-rinkeby.alchemyapi.io/v2/OLzklTRhXgHUl-XAAlqGmMpvWbogPYgM',
            http: 'https://eth-rinkeby.alchemyapi.io/v2/OLzklTRhXgHUl-XAAlqGmMpvWbogPYgM',
        },
    },
};

const clients = {
    eth: {
        websocket: null as null | AlchemyWeb3,
        http: null as null | AlchemyWeb3,
    },
    polygon: {
        websocket: null as null | AlchemyWeb3,
        http: null as null | AlchemyWeb3,
    },
    rinkeby: {
        websocket: null as null | AlchemyWeb3,
        http: null as null | AlchemyWeb3,
    },
};

export const Web3Client = (
    chain?: 'eth' | 'polygon' | 'rinkeby',
    channel = 'websocket' as 'websocket' | 'http'
) => {
    const chainName = (chain || process.env.GATSBY_NFT_CHAIN) as
        | 'eth'
        | 'polygon'
        | 'rinkeby';
    let client = clients?.[chainName]?.[channel];

    if (!client) {
        console.log(
            'using provider',
            providers[process.env.GATSBY_STAGE as 'dev' | 'prod'][chainName][
                channel
            ]
        );
        clients[chainName][channel] = createAlchemyWeb3(
            providers[process.env.GATSBY_STAGE as 'dev' | 'prod'][chainName][
                channel
            ]
        );
        client = clients[chainName][channel];
    }

    return client;
};
