import React from 'react';
import { GenverseTokenType } from '../../../../../../types';
import {
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { chunk, orderBy, startCase } from 'lodash';
import { Link } from 'gatsby';
import moment from 'moment';

export default ({ token }: { token: GenverseTokenType }) => {
    const sortedAttributes = orderBy(token?.metadata?.attributes, [
        'traitType',
    ]);

    const groupedAttributes = chunk(sortedAttributes, 2);

    return (
        <Table size="lg" variant="unstyled">
            {groupedAttributes?.map((attributes, attributesIndex) => (
                <React.Fragment key={attributesIndex}>
                    <Thead>
                        <Tr>
                            {attributes?.map(({ traitType }, index) => (
                                <Th
                                    key={`td-${traitType}-${index}`}
                                    px={0}
                                    color={useColorModeValue(
                                        'rgba(0, 0, 0, 0.5)',
                                        'rgba(255, 255, 255, 0.5)'
                                    )}
                                    fontSize={['0.8rem', '1rem']}
                                    fontWeight="normal"
                                    textTransform="unset"
                                    paddingBottom=".2rem"
                                >
                                    {typeof traitType === 'string'
                                        ? startCase(traitType?.toLowerCase())
                                        : traitType}
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody marginBottom="1rem">
                        <Tr width="50%">
                            {attributes?.map(
                                (
                                    {
                                        traitType,
                                        value,
                                        rarityPercentage,
                                        displayType,
                                    },
                                    index
                                ) => (
                                    <Td
                                        key={`td-${traitType}-${index}`}
                                        px={0}
                                        paddingTop={0}
                                        color={useColorModeValue(
                                            'black',
                                            'white'
                                        )}
                                        fontSize={['0.8rem', '1rem']}
                                        fontWeight="bold"
                                        paddingBottom=".1rem"
                                    >
                                        <Link
                                            to={`/collection/${token.chain}/${
                                                token.tokenAddress
                                            }?tab=1&traitFilters=${JSON.stringify(
                                                {
                                                    [`${attributes[index].traitType}`]:
                                                        value,
                                                }
                                            )}`}
                                        >
                                            <Text color="primary">
                                                {`${
                                                    (displayType === 'date'
                                                        ? moment(
                                                              value * 1000
                                                          ).format('llll')
                                                        : startCase(
                                                              value || ''
                                                          )) || 'N/A'
                                                }`}
                                            </Text>
                                        </Link>
                                        {!!rarityPercentage && (
                                            <Text
                                                fontSize={['0.7rem', '0.8rem']}
                                                color={useColorModeValue(
                                                    'rgba(0, 0, 0, 0.5)',
                                                    'rgba(255, 255, 255, 0.5)'
                                                )}
                                            >{`${`${rarityPercentage
                                                ?.toFixed(2)
                                                ?.replace(
                                                    '.00',
                                                    ''
                                                )}% with trait`}`}</Text>
                                        )}
                                    </Td>
                                )
                            )}
                        </Tr>
                    </Tbody>
                </React.Fragment>
            ))}
        </Table>
    );
};
