import React, { useState } from 'react';
import {
    Flex,
    Input,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import { navigate } from 'gatsby';
import G3Button from '../G3Button';

export default () => {
    const [searchAddress, setSearchAddress] = useState('');
    const onSearch = () => {
        if (searchAddress?.length) {
            let searchUrl = `/page/${searchAddress}`;

            const { Web3Client } = require('../../../../api/Web3');

            const Web3 = Web3Client();

            const isAddress =
                typeof window !== 'undefined' &&
                Web3.utils.isAddress(searchAddress?.toLowerCase());

            if (!isAddress && !searchAddress?.toLowerCase()?.endsWith('.eth')) {
                searchUrl = `/page/${searchAddress}.eth`;
            }

            navigate(searchUrl);
        }
    };

    const [isMobile] = useMediaQuery('(max-width: 768px)');

    return (
        <Flex
            width="100%"
            border={useColorModeValue(
                'solid 1px rgba(0, 0, 0, .2)',
                'solid 1px rgba(255, 255, 255, .2)'
            )}
            padding=".5rem"
            align="center"
            borderRadius="0.375rem"
        >
            <Input
                size={isMobile ? 'sm' : 'md'}
                autoFocus
                placeholder="genv3rse.eth or 0x12345..."
                border={0}
                flex={1}
                value={searchAddress}
                onChange={(e) => setSearchAddress(e.target.value.trim())}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') onSearch();
                }}
                autoCapitalize="none"
                autoComplete="none"
            />
            <G3Button size={isMobile ? 'sm' : 'md'} marginLeft=".5rem" onClick={onSearch}>
                Search Wallet
            </G3Button>
        </Flex>
    );
};
