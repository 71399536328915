import React from 'react';
import toast from 'react-hot-toast';
import CopyToClipboard from 'react-copy-to-clipboard';

export default ({
    children,
    copyText,
}: {
    children: React.ReactNode;
    copyText: string;
}) => (
    <span
        data-tip="Copy to clipboard"
        style={{
            cursor: 'pointer',
        }}
    >
        <CopyToClipboard
            text={copyText}
            onCopy={() =>
                toast.success('Copied successfully!', {
                    style: {
                        background: '#333',
                        color: '#fff',
                    },
                })
            }
        >
            {children}
        </CopyToClipboard>
    </span>
);
