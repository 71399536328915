import { useEffect, useState } from 'react';
import resolveENS from '../utils/resolveENS';

export default (walletAddress: string) => {
    const [result, setResult] = useState(
        {} as { ensName?: string; address: string }
    );

    useEffect(() => {
        (async () => {
            if (walletAddress?.length) {
                const { ensName, address } = await resolveENS(walletAddress);

                setResult({
                    address,
                    ensName,
                });
            }
        })();
    }, [walletAddress]);

    return result;
};
