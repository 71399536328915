import {
    Box,
    Flex,
    Grid,
    GridItem,
    Text,
    useMediaQuery,
} from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo } from 'react';
import {
    GenverseTokenType,
    QuickViewContextValueContext,
} from '../../../../types';
// @ts-ignore
import NFTCard from './../general/NFTCard';
import './style.css';
import { Link } from 'gatsby';
import InfiniteScroll from 'react-infinite-scroll-component';
import { QuickViewNFTContext } from '../MetaPage/contexts/QuickViewNFTContext';
import { GridOptions } from '@chakra-ui/layout/dist/declarations/src/grid';
import G3LogoSpinner from '../general/G3LogoSpinner';

export default ({
    isCurrentUser,
    tokens,
    hasNextPage,
    loadNextPage,
    gridProps,
}: {
    isCurrentUser?: boolean;
    tokens: GenverseTokenType[];
    hasNextPage?: boolean;
    loadNextPage?: any;
    gridProps?: GridOptions;
}) => {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [isTablet] = useMediaQuery(
        '(min-device-width : 768px) and (max-device-width : 1024px)'
    );
    const [isDesktop] = useMediaQuery(
        '(min-device-width : 1024px) and (max-device-width : 1824px)'
    );
    const [isLargeScreen] = useMediaQuery('(min-device-width : 1824px)');

    const getGridItems = useCallback(() => {
        if (isMobile) return 2;
        if (isTablet) return 3;
        if (isDesktop) return 4;
        if (isLargeScreen) return 5;
    }, [isMobile, isTablet, isDesktop, isLargeScreen]);

    const { displayQuickViewToken } = useContext(
        QuickViewNFTContext
    ) as QuickViewContextValueContext;

    const gridItems = useMemo(() => {
        return tokens?.map((token) => (
            <GridItem
                key={`${token.chain}#${token.tokenAddress}#${token.tokenId}`}
            >
                <Link
                    to={`/token/${token.chain}/${token.tokenAddress}/${token.tokenId}`}
                >
                    <NFTCard
                        token={token}
                        key={`${token.chain}#${token.tokenAddress}#${token.tokenId}`}
                        // disableTilt={isCurrentUser && editMode}
                        onClickQuickView={() => displayQuickViewToken(token)}
                        isCurrentUser={isCurrentUser}
                    />
                </Link>
            </GridItem>
        ));
    }, [tokens]);

    return (
        <Box padding={['0 1rem', 0]}>
            <InfiniteScroll
                scrollThreshold={0.1}
                dataLength={tokens?.length || 0}
                next={loadNextPage}
                hasMore={hasNextPage as boolean}
                style={{
                    padding: '0 0 2rem 0',
                }}
                loader={
                    <Flex
                        align="center"
                        justify="center"
                        backgroundColor="rgba(255, 255, 255, 0.01)"
                        borderRadius="0.375rem"
                        padding="1rem"
                        marginTop="1rem"
                    >
                        <G3LogoSpinner
                            containerProps={{
                                w: '5rem',
                            }}
                        />
                        <Text>Loading...</Text>
                    </Flex>
                }
            >
                <Grid
                    gap="1rem"
                    templateColumns={`repeat(${getGridItems()}, 1fr)`}
                    {...gridProps}
                >
                    {gridItems}
                </Grid>
            </InfiniteScroll>
        </Box>
    );
};
