import { isEqual } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { G3lyticsContext } from '../G3lyticsProvider';
import { PageEventProperties } from '../../useTrackPage';

export default ({
    pageEventName,
    pageChangeProperties,
    children,
}: {
    pageEventName: string;
    // data added in this triggers the useEffect which tracks a page e.g if address changes
    // then the metapage has changed
    pageChangeProperties?: PageEventProperties;
    children: React.ReactNode;
}) => {
    const EVENT_NAME = `Viewed ${pageEventName}`;
    // @ts-ignore
    const { hasEventStarted, trackPageStart, trackPageEnd, currentEventRef } =
        useContext(G3lyticsContext);

    const [isVisible, setIsVisible] = useState(true);
    const isPageVisible = usePageVisibility();
    const [pageProperties, setPageProperties] = useState(pageChangeProperties);

    useEffect(() => {
        if (isPageVisible && !isVisible) setIsVisible(true);
        if (!isPageVisible && isVisible) setIsVisible(false);
    }, [isPageVisible]);

    useEffect(() => {
        if (!isEqual(pageChangeProperties, pageProperties)) {
            trackPageEnd();
            setPageProperties(pageChangeProperties);
        }
    }, [pageChangeProperties]);

    useEffect(() => {
        if (isVisible) {
            if (!hasEventStarted) {
                trackPageStart(EVENT_NAME, pageProperties);
            }
        } else {
            if (hasEventStarted) {
                trackPageEnd();
            }
        }
    }, [isVisible, pageProperties, hasEventStarted]);

    // When the user closes the window or tab then we trackPageEnd via navigator.sendBeacon
    const onCloseTabWindow = useCallback(
        (event) => {
            if (
                document.visibilityState === 'hidden' &&
                !!currentEventRef.current
            ) {
                const blob = new Blob(
                    [JSON.stringify(currentEventRef.current)],
                    {
                        type: 'text/plain; charset=UTF-8',
                    }
                );

                navigator.sendBeacon(
                    process.env.GATSBY_ANALYTICS_TRACK_PAGE_URL as string,
                    blob
                );
            }
        },
        [currentEventRef?.current]
    );


    useEffect(() => {
        document.addEventListener('visibilitychange', onCloseTabWindow);
        document.addEventListener('pagehide', onCloseTabWindow);
        // window.addEventListener('unload', onCloseTabWindow);
        return () => {
            document.removeEventListener('unload', onCloseTabWindow);
            document.removeEventListener('pagehide', onCloseTabWindow);
            // window.removeEventListener('unload', onCloseTabWindow);
        };
    }, []);

    return <>{children}</>;
};
