/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Header from '../Header';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import './style.css';
import Container from '../Container';
import { GLOW_BACKGROUNDS } from '../../../consts/THEME_OPTIONS';
import { BackgroundColoursTypes } from '../../../../../types';

export default ({
    largeContainer,
    extraLargeContainer,
    noContainer,
    noHeader,
    showNavBarLogo,
    showSearchBar,
    showNavLinks,
    showConnectButton,
    children,
    styles,
    bgColor,
}: {
    largeContainer?: boolean;
    extraLargeContainer?: boolean;
    noContainer?: boolean;
    noHeader?: boolean;
    showNavBarLogo?: boolean;
    showSearchBar?: boolean;
    showNavLinks?: boolean;
    showConnectButton?: boolean;
    children: React.ReactNode;
    styles?: {};
    bgColor?: BackgroundColoursTypes;
}) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `);

    const BG_GLOW_IMAGE =
        GLOW_BACKGROUNDS?.[bgColor || 'V1_PURPLE']?.image ||
        GLOW_BACKGROUNDS.V1_PURPLE?.image;

    return (
        <Box>
            {!noHeader && (
                <Header
                    siteTitle={data.site.siteMetadata?.title}
                    siteDescription={data.site.siteMetadata?.description}
                    showNavBarLogo={showNavBarLogo}
                    showConnectButton={showConnectButton}
                    showSearchBar={showSearchBar}
                    showNavLinks={showNavLinks}
                />
            )}
            <Image
                position="fixed"
                top={0}
                right={0}
                bottom={0}
                left={0}
                width="100vw"
                height="100vh"
                zIndex="-1000"
                src={BG_GLOW_IMAGE}
            />

            <Flex direction="column" align="center" w="100%" {...styles}>
                <Container
                    noContainer={noContainer}
                    largeContainer={largeContainer}
                    extraLargeContainer={extraLargeContainer}
                >
                    <main
                        style={{
                            minHeight: '75vh',
                        }}
                    >
                        {children}
                    </main>
                    <footer
                        style={{
                            padding: '2rem',
                            textAlign: 'center',
                        }}
                    >
                        <a
                            href="https://genflow.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Text fontWeight="bold">By GENFLOW</Text>
                        </a>
                    </footer>
                </Container>
            </Flex>
        </Box>
    );
};
