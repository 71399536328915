import React, { createContext, useState } from 'react';
import {
    GenverseTokenType,
    QuickViewContextValueContext,
} from '../../../../../types';
import { isEmpty } from 'lodash';
import ViewNFT from '../../ViewNFT';

export const QuickViewNFTContext = createContext({});

const QuickViewNFTProvider = ({ children }: { children: React.ReactNode }) => {
    const [quickViewToken, setQuickViewToken] = useState(
        null as GenverseTokenType | null
    );

    const displayQuickViewToken = (token: GenverseTokenType) => {
        setQuickViewToken(token);
    };

    const clearQuickViewToken = () => setQuickViewToken(null);

    return (
        <QuickViewNFTContext.Provider
            value={
                {
                    displayQuickViewToken,
                    clearQuickViewToken,
                } as QuickViewContextValueContext
            }
        >
            {children}

            <ViewNFT
                asModal
                viewType={!isEmpty(quickViewToken) ? 'modal' : null}
                cacheToken={quickViewToken as GenverseTokenType}
                onClose={clearQuickViewToken}
            />
        </QuickViewNFTContext.Provider>
    );
};

export default QuickViewNFTProvider;
