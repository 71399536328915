import React, { useEffect, useState } from 'react';
import { Flex, Image, Text, useColorModeValue } from '@chakra-ui/react';
import { GenverseTokenType, MarketPlaceData } from '../../../../../../types';
import OpenSeaLogo from '../../../../../assets/opensea-logo.svg';
import { Link } from 'gatsby';
import axios from 'axios';
import { useQuery } from '@apollo/react-hooks';
import SearchContractMarketplaceDataQuery from '../../../../../api/searchContractMarketplaceData.query.gql';
import G3LogoSpinner from '../../../general/G3LogoSpinner';

const MKT_PLCE_OPENSEA = 'OpenSea';
export default ({
    token,
    days,
    periodLabel,
    style,
}: {
    token?: GenverseTokenType;
    days: number;
    periodLabel: string;
    style?: any;
}) => {
    if (!token) return null;

    const { data, loading } = useQuery(SearchContractMarketplaceDataQuery, {
        variables: {
            chain: token?.chain,
            contractAddress: token?.tokenAddress,
        },
        skip: !token?.chain || !token?.tokenAddress,
    });

    const [marketplaceData, setMarketplaceData] = useState([
        {
            name: MKT_PLCE_OPENSEA,
            brandLogo: OpenSeaLogo,
            collectionUrlTemplate: (slug: string) =>
                `https://opensea.io/collection/${slug}`,
            getMarketPlaceAPIStats: async () => {
                const response = await axios
                    .get(
                        `https://api.opensea.io/api/v1/asset_contract/${token.tokenAddress}`
                    )
                    .catch((e) => {});

                return response || {};
            },
        },
    ] as MarketPlaceData[]);

    const marketplacesBackendData = data?.searchContractMarketplaceData?.data;

    useEffect(() => {
        if (!loading && !!marketplacesBackendData) {
            setMarketplaceData((currentMarketplaceData) =>
                currentMarketplaceData.map((marketplace) => {
                    const dbData = marketplacesBackendData.find(
                        ({ name }: { name: string }) =>
                            name === marketplace.name
                    );
                    return {
                        ...marketplace,
                        ...dbData,
                    };
                })
            );
        }
    }, [loading, marketplacesBackendData]);

    const getMarketPlaceData = async () => {
        for (const marketplace of marketplaceData) {
            let marketPlaceAPIStats = {};

            if (marketplace?.getMarketPlaceAPIStats) {
                const apiResponse = await marketplace?.getMarketPlaceAPIStats();
                marketPlaceAPIStats = apiResponse?.data;
            }

            setMarketplaceData((prevMarketPlaceData: any) => {
                return prevMarketPlaceData?.map(
                    (marketPlaceItem: MarketPlaceData) => {
                        if (marketPlaceItem?.name === marketplace.name) {
                            return {
                                ...marketPlaceItem,
                                marketPlaceAPIStats,
                                fetchedMarketPlaceAPIStats: true,
                            };
                        }

                        return marketPlaceItem;
                    }
                );
            });
        }
    };

    useEffect(() => {
        if (token) {
            getMarketPlaceData();
        }
    }, [token]);

    return (
        <>
            {marketplaceData?.map((marketplace) => (
                <Link
                    to={
                        (!!marketplace?.marketPlaceAPIStats?.collection?.slug &&
                            marketplace.collectionUrlTemplate(
                                marketplace?.marketPlaceAPIStats?.collection
                                    ?.slug
                            )) as string
                    }
                    target="_blank"
                    key={`${marketplace.name}`}
                >
                    <Flex
                        direction="column"
                        justify="center"
                        align="center"
                        background={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'rgba(218, 175, 255, 0.05)'
                        )}
                        backdropFilter={useColorModeValue(
                            'brightness(100%)',
                            'brightness(200%)'
                        )}
                        borderRadius={'0.375rem'}
                        padding=".5rem 1rem"
                        {...style}
                    >
                        <Flex justify="center" align="center">
                            {!!marketplace?.brandLogo && (
                                <Image
                                    src={marketplace?.brandLogo}
                                    width={4}
                                    height={4}
                                    alt={marketplace.name}
                                    marginRight=".5rem"
                                />
                            )}
                            {marketplace?.lowestSales ? (
                                <Text
                                    fontSize="0.8rem"
                                    fontWeight="700"
                                    color={useColorModeValue('black', 'white')}
                                >
                                    {marketplace?.lowestSales?.[days]?.priceEth
                                        ? `${marketplace.lowestSales[
                                              days
                                          ].priceEth
                                              ?.toFixed(2)
                                              ?.replace('.00', '')} ETH`
                                        : 'N/A'}
                                </Text>
                            ) : (
                                <G3LogoSpinner imageProps={{ w: '1rem' }} />
                            )}
                        </Flex>
                        <Text
                            marginTop=".2rem"
                            fontSize={'0.7rem'}
                        >{`Lowest Sale ${periodLabel}`}</Text>
                    </Flex>
                </Link>
            ))}
        </>
    );
};
