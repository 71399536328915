import React from 'react';
import OpenSeaLogo from '../../../../assets/opensea-logo.svg';
import EtherscanDarkLogo from '../../../../assets/etherscan-logo-light-circle.svg';
import EtherscanLightLogo from '../../../../assets/etherscan-logo-circle.svg';
import PolygonscanLogo from '../../../../assets/polygonscan-logo-circle.svg';
import { ExternalPlatformLinks, GenverseTokenType } from '../../../../../types';
import { Button, Image, useColorModeValue } from '@chakra-ui/react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'gatsby';
import useOpenSeaContractData from '../../../hooks/useOpenSeaContractData';
import getTokenMetadataImage from '../../../utils/getTokenMetadataImage';

export const METADATA_EXTERNAL_URL = 'METADATA_EXTERNAL_URL';
export const DOWNLOAD_ASSET = 'DOWNLOAD_ASSET';
export const OPENSEA_TOKEN_URL = 'OPENSEA_TOKEN_URL';
export const OPENSEA_CONTRACT_URL = 'OPENSEA_CONTRACT_URL';
export const ETHERSCAN = 'ETHERSCAN';
export const POLYGONSCAN = 'POLYGONSCAN';

const ExternalLinks = ({
    token,
    showViewTokensCTA,
}: {
    token: GenverseTokenType;
    showViewTokensCTA?: boolean;
}) => {
    const EXTERNAL_LINKS = {
        [OPENSEA_TOKEN_URL]: {
            urlTemplate: (token: GenverseTokenType) =>
                `https://opensea.io/assets/${
                    token.chain === 'polygon' ? 'matic/' : ''
                }${token.tokenAddress}/${token.tokenId}`,
            logo: OpenSeaLogo,
        },
        [OPENSEA_CONTRACT_URL]: {
            urlTemplate: (token: GenverseTokenType) => {
                // @ts-ignore
                return token?.openSeaContractUrl;
            },
            logo: OpenSeaLogo,
        },
        [ETHERSCAN]: {
            urlTemplate: (token: GenverseTokenType) =>
                `https://etherscan.io/address/${token.tokenAddress}`,
            logo: useColorModeValue(EtherscanLightLogo, EtherscanDarkLogo),
        },
        [POLYGONSCAN]: {
            urlTemplate: (token: GenverseTokenType) =>
                `https://polygonscan.com/address/${token.tokenAddress}`,
            logo: PolygonscanLogo,
        },
        [DOWNLOAD_ASSET]: {
            urlTemplate: (token: GenverseTokenType) =>
                getTokenMetadataImage(token),
        },
        [METADATA_EXTERNAL_URL]: {
            urlTemplate: (token: GenverseTokenType) =>
                token?.metadata?.externalUrl,
            logoComponent: (
                <FiExternalLink
                    size="1.5rem"
                    color="rgba(0, 0, 0, 0.5)"
                    style={{ marginRight: '0.5rem' }}
                />
            ),
        },
    };

    const { data: openSeaData } = useOpenSeaContractData(
        token?.tokenAddress
    ) as {
        data: any;
    };

    const openSeaContractUrl =
        !!openSeaData?.collection?.slug &&
        `https://opensea.io/collection/${openSeaData.collection.slug}`;

    const externalLinks = [
        !!token?.metadata?.externalUrl && METADATA_EXTERNAL_URL,
        token?.tokenId ? OPENSEA_TOKEN_URL : OPENSEA_CONTRACT_URL,
        token?.chain?.toLowerCase() === 'eth' && ETHERSCAN,
        token?.chain?.toLowerCase() === 'polygon' && POLYGONSCAN,
    ].filter(Boolean) as ExternalPlatformLinks[];

    if (!token?.tokenId) {
        token = {
            ...token,
            openSeaContractUrl,
        } as GenverseTokenType;
    }
    return (
        <>
            {showViewTokensCTA && (
                <Link
                    to={`/collection/${token.chain}/${token.tokenAddress}/tab=1`}
                >
                    <Button
                        marginRight="0.5rem"
                        // onClick={onSearch}
                        backgroundColor="primary"
                        color="white"
                        size="sm"
                    >
                        View Tokens
                    </Button>
                </Link>
            )}
            {externalLinks.map((platform, index) => {
                // @ts-ignore
                const externalLink = EXTERNAL_LINKS[platform] as {
                    urlTemplate: (token: GenverseTokenType) => string;
                    logo: string;
                    logoComponent: React.ReactNode;
                };

                return (
                    <Link
                        to={externalLink.urlTemplate(token)}
                        target="_blank"
                        key={`${index}-${externalLink.urlTemplate(token)}`}
                    >
                        {!!externalLink?.logo && (
                            <Image
                                marginRight="0.5rem"
                                width={6}
                                height={6}
                                src={externalLink.logo}
                                alt={token.name}
                            />
                        )}
                        {!!externalLink?.logoComponent &&
                            externalLink.logoComponent}
                    </Link>
                );
            })}
        </>
    );
};

export default ExternalLinks;
