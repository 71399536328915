import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    GenverseTokenType,
    QuickViewContextValueContext,
} from '../../../../types';
import NFTImage from '../general/NFTImage';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    AspectRatio,
    Box,
    Flex,
    Grid,
    GridItem,
    Image,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import { BiRefresh } from 'react-icons/bi';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import './style.css';
import Tilt from 'react-parallax-tilt';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ExternalLinks from '../../components/general/ExternalLinks';
import TokenAttributesTable from './components/TokenAttributesTable';
import TokenMarketPlaceData from './components/TokenMarketPlaceData';
import NFTDataTable from './components/NFTDataTable';
import Layout from '../base/Layout';
import useGenverseToken from '../../hooks/useGenverseToken';
import { QuickViewNFTContext } from '../MetaPage/contexts/QuickViewNFTContext';
import ChainImage from '../general/ChainImage';
import PropertiesIcon from '../../../assets/properties-icon.svg';
import { parseInt } from 'lodash';
import RarityExplanationModal from '../general/RarityExplanationModal';
import { GLOW_BACKGROUNDS } from '../../consts/THEME_OPTIONS';
import G3lyticsPageTracker from '../../g3lytics/contexts/components/G3lyticsPageTracker';
import TokenOwnerCard from '../general/TokenOwnerCard';
import useOpenSeaContractData from '../../hooks/useOpenSeaContractData';
import { Link } from 'gatsby';
import G3LogoSpinner from '../general/G3LogoSpinner';
import G3Button from '../general/G3Button';

export default ({
    asModal,
    viewType = 'page',
    onClose,
    chain,
    tokenAddress,
    tokenId,
    cacheToken,
    styles,
}: {
    asModal: boolean;
    viewType?: 'page' | 'modal' | undefined | null;
    onClose: () => void;
    chain?: string;
    tokenAddress?: string;
    tokenId?: string;
    cacheToken?: GenverseTokenType | null;
    styles?: {};
}) => {
    if (!viewType) return <></>;

    const [token, setToken] = useState(
        cacheToken as unknown as GenverseTokenType
    );
    const { displayQuickViewToken } = useContext(
        QuickViewNFTContext
    ) as QuickViewContextValueContext;

    // Maintain cacheToken pro with internal state `token`
    useEffect(() => {
        if (cacheToken) {
            setToken(cacheToken);
        }
    }, [cacheToken]);

    const [scale] = useState(1);
    const [lightboxOpen, setLightboxOpen] = useState(false);

    const {
        genverseToken,
        loading,
        refreshingTokenMetadata,
        refreshTokenMetadata,
    } = useGenverseToken(
        chain || cacheToken?.chain,
        tokenAddress || cacheToken?.tokenAddress,
        tokenId || cacheToken?.tokenId
    );

    const { loading: openSeaLoading, data: openSeaData } =
        useOpenSeaContractData(genverseToken?.tokenAddress) as any;

    const openSeaImageUrl =
        // @ts-ignore
        openSeaData?.collection?.large_image_url ||
        // @ts-ignore
        openSeaData?.collection?.image_url ||
        // @ts-ignore
        openSeaData?.image_url;

    const [showRarityExplanationModal, setShowRarityExplanationModal] =
        useState(null as null | GenverseTokenType); // as can pass data through this later

    // Sync local state token with fetched from hook
    useEffect(() => {
        if (!loading && !!genverseToken) {
            (async () => {
                setToken(genverseToken);
            })();
        }
    }, [loading, genverseToken]);

    const LightBoxContainer = () =>
        lightboxOpen ? (
            <Lightbox
                reactModalStyle={{
                    overlay: {
                        zIndex: 2000,
                    },
                }}
                mainSrc={
                    token?.metadata?.animationUrl || token?.metadata?.image
                }
                nextSrc={
                    token?.metadata?.animationUrl ? token?.metadata?.image : ''
                }
                onCloseRequest={() => {
                    setLightboxOpen(false);
                    if (asModal) displayQuickViewToken(token);
                }}
            />
        ) : (
            <></>
        );

    const [isMobile] = useMediaQuery('(max-width: 768px)');

    const NFTDataAccordionItem = useMemo(
        () => () =>
            (
                <AccordionItem borderColor="rgba(255, 255, 255, 0.1)">
                    <AccordionButton
                        background={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'transparent'
                        )}
                    >
                        <ChainImage
                            containerProps={{
                                marginRight: '1rem',
                            }}
                            chain={token?.chain as 'eth' | 'polygon'}
                        />
                        <Text
                            fontSize={['1rem', '1.2rem']}
                            flex="1"
                            textAlign="left"
                            color={useColorModeValue('black', 'white')}
                        >
                            NFT
                        </Text>
                        <AccordionIcon
                            color={useColorModeValue('black', 'white')}
                        />
                    </AccordionButton>
                    <AccordionPanel paddingTop={0}>
                        <NFTDataTable token={token} />
                    </AccordionPanel>
                </AccordionItem>
            ),
        [token]
    );

    const NFTLayout = ({ mouseImageTilt }: { mouseImageTilt?: boolean }) => {
        const ImageWrapper = ({ children }: { children: React.ReactNode }) =>
            mouseImageTilt ? (
                <Tilt scale={scale} tiltMaxAngleX={7} tiltMaxAngleY={7}>
                    {children}
                </Tilt>
            ) : (
                <>{children}</>
            );

        return (
            <>
                <Flex>
                    {!token && <G3LogoSpinner containerProps={{ h: '20vh' }} />}
                    {!!token && (
                        <Grid
                            width="100%"
                            autoColumns={'minmax(0, 1fr)'}
                            autoFlow={['row', 'column']}
                            gap={0}
                        >
                            <GridItem>
                                <AspectRatio
                                    height="100%"
                                    ratio={1}
                                    cursor={'pointer'}
                                    onClick={() => {
                                        if (
                                            !!token?.metadata?.image ||
                                            token?.metadata?.animationUrl
                                        ) {
                                            setLightboxOpen(true);
                                        }
                                    }}
                                >
                                    <ImageWrapper>
                                        <NFTImage
                                            token={token}
                                            alt={token?.metadata?.name}
                                            imageProps={{
                                                objectPosition: isMobile
                                                    ? 'center'
                                                    : 'top right',
                                            }}
                                        />
                                    </ImageWrapper>
                                </AspectRatio>
                            </GridItem>
                            <GridItem>
                                <Box padding={['0 1rem', '0 0 0 2rem']}>
                                    <Flex
                                        direction="row"
                                        align="center"
                                        justify={['center', 'flex-start']}
                                        margin={['1rem 0', '0 0 1rem 0']}
                                    >
                                        {!openSeaLoading && !!openSeaImageUrl && (
                                            <AspectRatio
                                                width="4rem"
                                                ratio={1}
                                                cursor={'pointer'}
                                                borderRadius={'50%'}
                                                overflow="hidden"
                                                marginRight="1rem"
                                            >
                                                <Image
                                                    key={openSeaImageUrl}
                                                    height="100%"
                                                    width="100%"
                                                    objectPosition="center"
                                                    objectFit="contain"
                                                    border={0}
                                                    src={openSeaImageUrl}
                                                    // alt={alt}
                                                    // onError={(e) => addError('image')}
                                                    // onLoad={() => setLoading(false)}
                                                    loading="lazy"
                                                />
                                            </AspectRatio>
                                        )}
                                        <Flex
                                            direction="column"
                                            margin={['1rem 0', 0]}
                                        >
                                            <Text
                                                fontSize={['1rem', '1.2rem']}
                                                onClick={onClose}
                                                fontWeight="900"
                                            >
                                                {token?.metadata?.name ||
                                                    token?.contract?.name}
                                            </Text>

                                            <Flex direction="column">
                                                <Box>
                                                    <Text
                                                        fontSize={[
                                                            '1rem',
                                                            '1.2rem',
                                                        ]}
                                                        fontWeight="900"
                                                        opacity=".5"
                                                    >
                                                        {token?.symbol ||
                                                            token?.contract
                                                                ?.symbol}
                                                    </Text>
                                                </Box>

                                                <Link
                                                    to={`/collection/${token.chain}/${token.tokenAddress}?tab=0`}
                                                >
                                                    <G3Button
                                                        size="sm"
                                                        marginTop=".5rem"
                                                        variant="outline"
                                                    >
                                                        View Collection →
                                                    </G3Button>
                                                </Link>
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    {parseInt(token?.amount || '1') > 1 && (
                                        <Text
                                            fontSize="1rem"
                                            textAlign={['center', 'left']}
                                            fontWeight="300"
                                            marginBottom="1rem"
                                        >
                                            {`x${token.amount}`}
                                        </Text>
                                    )}
                                    <Flex
                                        direction="row"
                                        wrap="wrap"
                                        align="center"
                                        justify={['center', 'flex-start']}
                                        gap="1rem"
                                        alignItems="stretch"
                                    >
                                        <TokenOwnerCard
                                            token={token}
                                            containerProps={{
                                                flex: [1, 0.4],
                                            }}
                                        />

                                        {!!token?.rarityRank && (
                                            <Flex
                                                padding={'1rem'}
                                                background={useColorModeValue(
                                                    'rgba(0, 0, 0, 0.1)',
                                                    'rgba(218, 175, 255, 0.05)'
                                                )}
                                                backdropFilter={useColorModeValue(
                                                    'brightness(100%)',
                                                    'brightness(200%)'
                                                )}
                                                borderRadius={'0.375rem'}
                                                display="flex"
                                                direction="column"
                                                align="center"
                                                justify="space-between"
                                                cursor="pointer"
                                                onClick={() =>
                                                    setShowRarityExplanationModal(
                                                        token
                                                    )
                                                }
                                            >
                                                <AiOutlineQuestionCircle
                                                    size="1rem"
                                                    color={useColorModeValue(
                                                        'rgba(0, 0, 0, 0.5)',
                                                        'rgba(255, 255, 255, 0.5)'
                                                    )}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '1rem',
                                                        right: '1rem',
                                                    }}
                                                />
                                                <Text
                                                    textAlign={'center'}
                                                    fontSize={'.8rem'}
                                                >
                                                    {`Top`}
                                                </Text>
                                                <Text
                                                    textAlign={'center'}
                                                    fontSize={[
                                                        '1rem',
                                                        '1.2rem',
                                                    ]}
                                                    fontWeight="800rem"
                                                >
                                                    {`${
                                                        token?.rarityTopPercent?.toFixed(
                                                            0
                                                        ) || ''
                                                    }%`}
                                                </Text>

                                                <Text
                                                    textAlign={'center'}
                                                    fontSize={'0.8rem'}
                                                >
                                                    {`#${token.rarityRank} / ${token?.rarityRankBaseCount}`}
                                                </Text>
                                                <Text
                                                    fontSize=".8rem"
                                                    opacity=".7"
                                                    textAlign="center"
                                                >
                                                    Rarity Rank
                                                </Text>
                                            </Flex>
                                        )}
                                    </Flex>
                                    <Flex
                                        marginTop="1rem"
                                        align="center"
                                        justify={['center', 'flex-start']}
                                    >
                                        <TokenMarketPlaceData
                                            token={genverseToken}
                                            days={1}
                                            periodLabel="(24 Hours)"
                                        />
                                        <Box w="1rem" />
                                        <TokenMarketPlaceData
                                            token={genverseToken}
                                            days={7}
                                            periodLabel="(7 Days)"
                                        />
                                    </Flex>

                                    <Flex
                                        align="center"
                                        justify={['center', 'flex-start']}
                                        marginTop="2rem"
                                    >
                                        <ExternalLinks token={token} />

                                        {refreshingTokenMetadata ? (
                                            <G3LogoSpinner
                                                containerProps={{ w: '2rem' }}
                                            />
                                        ) : (
                                            <BiRefresh
                                                size="2rem"
                                                cursor="pointer"
                                                onClick={refreshTokenMetadata}
                                                color={useColorModeValue(
                                                    'rgba(0, 0, 0, 0.5)',
                                                    'rgba(255, 255, 255, 0.5)'
                                                )}
                                            />
                                        )}
                                    </Flex>

                                    {/*<TokenDescription token={token} />*/}

                                    {token?.metadata?.attributes?.length > 2 ? (
                                        <Accordion
                                            allowToggle
                                            marginTop="2rem"
                                            defaultIndex={0}
                                        >
                                            <AccordionItem
                                                borderColor={useColorModeValue(
                                                    'rgba(0, 0, 0, 0.1)',
                                                    'rgba(255, 255, 255, 0.1)'
                                                )}
                                            >
                                                <AccordionButton
                                                    background={useColorModeValue(
                                                        'rgba(0, 0, 0, 0.1)',
                                                        'transparent'
                                                    )}
                                                >
                                                    <Image
                                                        height="1.2rem"
                                                        width="2.5rem"
                                                        marginRight="1rem"
                                                        src={PropertiesIcon}
                                                    />
                                                    <Text
                                                        fontSize={[
                                                            '1rem',
                                                            '1.2rem',
                                                        ]}
                                                        flex="1"
                                                        textAlign="left"
                                                        color={useColorModeValue(
                                                            'black',
                                                            'white'
                                                        )}
                                                    >
                                                        Attributes
                                                    </Text>
                                                    <AccordionIcon
                                                        color={useColorModeValue(
                                                            'black',
                                                            'white'
                                                        )}
                                                    />
                                                </AccordionButton>
                                                <AccordionPanel paddingTop={0}>
                                                    <TokenAttributesTable
                                                        token={token}
                                                    />
                                                </AccordionPanel>
                                            </AccordionItem>
                                            <NFTDataAccordionItem />
                                        </Accordion>
                                    ) : (
                                        <>
                                            <TokenAttributesTable
                                                token={token}
                                            />
                                            <Accordion marginTop="1rem">
                                                <NFTDataAccordionItem />
                                            </Accordion>
                                        </>
                                    )}
                                </Box>
                            </GridItem>
                        </Grid>
                    )}
                </Flex>
            </>
        );
    };

    if (asModal) {
        return (
            <Modal
                isOpen={viewType === 'modal'}
                isCentered
                scrollBehavior="inside"
                // closeIcon={<CloseIcon w={4} h={4} color="primary" />}
                onClose={onClose}
            >
                <ModalOverlay backgroundColor={'rgba(0, 0, 0, 0.9);'} />
                <ModalContent
                    background="black"
                    overflowY="scroll"
                    w={['90vw', '60vw']}
                    maxW="auto"
                    border="solid 1px rgba(255, 255, 255, 0.15)"
                >
                    <LightBoxContainer />
                    <Box
                        height="100%"
                        width="100%"
                        backgroundImage={GLOW_BACKGROUNDS.V1_PURPLE.image}
                    >
                        <NFTLayout mouseImageTilt />
                    </Box>
                    <ModalCloseButton color={'white'} />
                </ModalContent>
            </Modal>
        );
    }

    return (
        <Layout
            extraLargeContainer
            showNavBarLogo
            showSearchBar
            showNavLinks
            showConnectButton
            styles={styles}
        >
            <G3lyticsPageTracker
                pageEventName="Token Page"
                pageChangeProperties={{
                    chain: token?.chain,
                    tokenAddress: token?.tokenAddress,
                    tokenId: token?.tokenId,
                }}
            >
                <LightBoxContainer />
                <NFTLayout />
                <RarityExplanationModal
                    token={token}
                    showModal={!!showRarityExplanationModal}
                    onClose={() => setShowRarityExplanationModal(null)}
                />
            </G3lyticsPageTracker>
        </Layout>
    );
};
