import React from 'react';
import {
    AspectRatio,
    Box,
    Flex,
    Heading,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { GenverseTokenType } from '../../../../types';
import useGenverseToken from '../../hooks/useGenverseToken';
import useGenversePage from '../../hooks/useGenversePage';
import { truncate } from 'lodash';
import ENSResolvedAddress from './ENSResolvedAddress';
import { Link } from 'gatsby';
import NFTImage from './NFTImage';
import G3LogoSpinner from './G3LogoSpinner';

export default ({
    token,
    containerProps,
    imageProps,
}: {
    token: GenverseTokenType;
    containerProps?: any;
    imageProps?: any;
}) => {
    if (!token?.chain || !token?.tokenAddress || !token?.tokenId)
        return (
            <Flex
                direction="row"
                align="center"
                justify="center"
                h="20vh"
                w="100%"
                background={useColorModeValue(
                    'rgba(0, 0, 0, 0.1)',
                    'rgba(218, 175, 255, 0.05)'
                )}
                backdropFilter={useColorModeValue(
                    'brightness(100%)',
                    'brightness(200%)'
                )}
                borderRadius={'0.375rem'}
            >
                <G3LogoSpinner
                    containerProps={{
                        h: '20vh',
                        w: '100%',
                        direction: 'row',
                        align: 'center',
                        justify: 'center',
                        background: 'rgba(218, 175, 255, 0.05)',
                        backdropFilter: 'brightness(200%)',
                        borderRadius: '0.375rem',
                    }}
                />
            </Flex>
        );

    const { genverseToken } = useGenverseToken(
        token.chain,
        token.tokenAddress,
        token.tokenId
    );

    const { genversePage, loading: pageLoading } = useGenversePage({
        address: genverseToken?.ownerOf,
    });

    return (
        <Flex
            background={useColorModeValue(
                'rgba(0, 0, 0, 0.1)',
                'rgba(218, 175, 255, 0.05)'
            )}
            backdropFilter={useColorModeValue(
                'brightness(100%)',
                'brightness(200%)'
            )}
            borderRadius={'0.375rem'}
            align="center"
            justify="center"
            padding="1rem"
            {...containerProps}
        >
            <Link
                style={{
                    flex: 1,
                }}
                to={`/page/${
                    genversePage?.ensName ||
                    genversePage?.address ||
                    genverseToken?.ownerOf ||
                    token?.ownerOf
                }`}
            >
                <Flex direction="row" align="center" justify="center">
                    <Box margin="0 .5rem">
                        <AspectRatio w={['3rem', '4rem']} ratio={1}>
                            <Box
                                width="100%"
                                height="100%"
                                cursor="pointer"
                                _hover={{
                                    opacity: 0.6,
                                }}
                                position="relative"
                            >
                                {pageLoading ? (
                                    <G3LogoSpinner
                                        containerProps={{
                                            border: 'solid 1px rgba(255, 255, 255, 0.2)',
                                            borderRadius: `50%`,
                                        }}
                                    />
                                ) : (
                                    <NFTImage
                                        border={useColorModeValue(
                                            'solid 1px rgba(0, 0, 0, 0.2)',
                                            'solid 1px rgba(255, 255, 255, 0.2)'
                                        )}
                                        borderRadius={`50%`}
                                        token={
                                            genversePage?.page
                                                ?.primaryToken as GenverseTokenType
                                        }
                                        alt={genversePage?.ensName}
                                    />
                                )}
                            </Box>
                        </AspectRatio>
                    </Box>

                    <Flex
                        direction="column"
                        align="flex-start"
                        justify="center"
                    >
                        <Heading size="md" fontWeight="800" fontSize="1rem">
                            {`#${truncate(token?.tokenId, {
                                length: 15,
                                omission: '...',
                            })}`}
                        </Heading>

                        <Box>
                            <Text
                                fontSize=".8rem"
                                opacity=".7"
                                marginTop=".5rem"
                            >
                                Owned By
                            </Text>
                            <ENSResolvedAddress
                                walletAddress={token.ownerOf}
                                suffix=" →"
                                shortAddress
                                styles={{
                                    marginTop: 0,
                                    fontSize: ['0.9rem', '1rem'],
                                }}
                                isLink={false}
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Link>
        </Flex>
    );
};
