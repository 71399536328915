import axios from 'axios';
import { useEffect, useState } from 'react';

const OPENSEA_CONTRACT_DATA_CACHE = {} as any;

export default (contractAddress: string) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        try {
            if (contractAddress) {
                if (!OPENSEA_CONTRACT_DATA_CACHE?.[contractAddress]) {
                    (async () => {
                        const requestResponse = await axios
                            .get(
                                `https://api.opensea.io/api/v1/asset_contract/${contractAddress}`
                            )
                            .catch((e) => {});
                        if (requestResponse?.data) {
                            setData(requestResponse.data as any);
                            OPENSEA_CONTRACT_DATA_CACHE[contractAddress] =
                                requestResponse?.data;
                        }
                        setLoading(false);
                    })();
                } else {
                    setData(OPENSEA_CONTRACT_DATA_CACHE[contractAddress]);
                    setLoading(false);
                }
            }
        } catch (e) {
            console.log('e', e);
            setLoading(false);
        }
    }, [contractAddress]);

    return { loading, data };
};
