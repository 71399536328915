import { useMutation, useQuery } from '@apollo/react-hooks';
import { GenverseTokenType } from '../../../types';
import { useMoralis } from 'react-moralis';
import { useEffect, useState } from 'react';
import GetTokenQuery from '../../api/getToken.query.gql';
import RefreshTokenMetadataMutation from '../../api/refreshTokenMetadata.mutation.gql';

export default (chain?: string, tokenAddress?: string, tokenId?: string) => {
    const { isAuthenticated, user } = useMoralis();
    const currentUserAddress = user?.get('ethAddress');
    const [isCurrentUser, setIsCurrentUser] = useState(false);

    const { data, loading, error } = useQuery(GetTokenQuery, {
        variables: {
            chain,
            tokenAddress,
            tokenId,
        },
        skip: !chain || !tokenAddress || !tokenId,
    });

    const genverseToken = !loading && data?.getGenverseToken?.data;

    // Maintain isCurrentUser state
    useEffect(() => {
        if (isAuthenticated) {
            setIsCurrentUser(
                currentUserAddress === genverseToken?.ownerOf?.toLowerCase()
            );
        }
    }, [isAuthenticated, user, genverseToken]);

    const [
        refreshingTokenMetadataMutation,
        { loading: refreshingTokenMetadata },
    ] = useMutation(RefreshTokenMetadataMutation);

    const refreshTokenMetadata = async () => {
        if (!!genverseToken && !loading) {
            await refreshingTokenMetadataMutation({
                variables: genverseToken,
            });
        }
    };

    return {
        genverseToken,
        isCurrentUser,
        loading,
        error,
        refreshingTokenMetadata,
        refreshTokenMetadata,
    } as {
        genverseToken: GenverseTokenType;
        isCurrentUser: boolean;
        loading: boolean;
        error: any;
        refreshingTokenMetadata: boolean;
        refreshTokenMetadata: () => Promise<void>;
    };
};
