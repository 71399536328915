import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import EthDiamond from '../../../assets/eth-diamond.gif';
import MaticDiamond from '../../../assets/matic-diamond.gif';

const chainImages = {
    eth: {
        displayName: 'Ethereum',
        image: EthDiamond,
    },
    polygon: {
        displayName: 'Polygon (Matic)',
        image: MaticDiamond,
    },
};
export default ({
    chain,
    imageProps,
    containerProps,
}: {
    chain: 'eth' | 'polygon';
    containerProps?: any;
    imageProps?: any;
}) =>
    chainImages?.[chain] ? (
        <Box
            height="2.5rem"
            width="2.5rem"
            marginRight="1rem"
            {...containerProps}
        >
            <Image
                height="100%"
                src={chainImages?.[chain].image}
                {...imageProps}
                alt={`This NFT exists on the ${chainImages?.[chain]?.displayName} blockchain.`}
            />
        </Box>
    ) : null;
