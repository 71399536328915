import React from 'react';
import { Flex, Image, keyframes, useColorModeValue } from '@chakra-ui/react';
import { FlexProps } from '@chakra-ui/layout/dist/declarations/src/flex';
import { ImageProps } from '@chakra-ui/image/dist/declarations/src/image';
import G3LogoLight from '../../../assets/g3-light-logo-icon.svg';
import G3LogoDark from '../../../assets/g3-dark-logo-icon.svg';

const animationKeyframes = keyframes`
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg); }
`;

const animation = `${animationKeyframes} 2.5s linear infinite`;

export default ({
    containerProps,
    imageProps,
}: {
    containerProps?: FlexProps;
    imageProps?: ImageProps;
}) => {
    const logoImage = useColorModeValue(G3LogoLight, G3LogoDark);

    return (
        <Flex
            w="100%"
            h="100%"
            justify="center"
            align="center"
            opacity=".5"
            padding=".5rem"
            {...containerProps}
        >
            <Image
                animation={animation}
                src={logoImage}
                width="2rem"
                height="2rem"
                {...imageProps}
            />
        </Flex>
    );
};
