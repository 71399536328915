import React from 'react';
import { GenverseTokenType } from '../../../../../../types';
import {Table, Tbody, Td, Th, Thead, Tr, Text, useColorModeValue} from '@chakra-ui/react';
import { startCase, truncate } from 'lodash';
import shortenAddress from '../../../../utils/shortenAddress';
import CopyClipboardWrapper from '../../../general/CopyClipboardWrapper';

export default ({ token }: { token: GenverseTokenType }) => {
    const data = [
        [
            {
                label: 'Contract Address',
                value: shortenAddress(token?.tokenAddress),
                isCopyEnabled: true,
                copyValue: token?.tokenAddress,
            },
            {
                label: 'Token ID',
                value: truncate(token?.tokenId, {
                    length: 15,
                    omission: '...',
                }),
                isCopyEnabled: true,
                copyValue: token?.tokenId,
            },
        ],
        [
            {
                label: 'Blockchain',
                value: { eth: 'Ethereum', polygon: 'Polygon' }[token?.chain],
                isCopyEnabled: true,
                copyValue: { eth: 'Ethereum', polygon: 'Polygon' }[
                    token?.chain
                ],
            },
            {
                label: 'Token Standard',
                value: token?.contractType,
                isCopyEnabled: true,
                copyValue: token?.contractType,
            },
        ],
    ];

    return (
        <Table size="lg" variant="unstyled">
            {data?.map((attributes, attributesIndex) => (
                <>
                    <Thead>
                        <Tr>
                            {attributes?.map(({ label }) => (
                                <Th
                                    px={0}
                                    color={useColorModeValue(
                                        'rgba(0, 0, 0, 0.5)',
                                        'rgba(255, 255, 255, 0.5)'
                                    )}
                                    fontSize={['0.8rem', '1rem']}
                                    fontWeight="normal"
                                    textTransform="unset"
                                    paddingBottom=".2rem"
                                >
                                    {startCase(label)}
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody marginBottom="1rem">
                        <Tr>
                            {attributes?.map(
                                // @ts-ignore
                                ({ value, isCopyEnabled, copyValue }) => (
                                    <Td
                                        px={0}
                                        paddingTop={0}
                                        color={useColorModeValue(
                                            'black',
                                            'white'
                                        )}
                                        fontSize={['0.8rem', '1rem']}
                                        fontWeight="bold"
                                        paddingBottom=".1rem"
                                    >
                                        {isCopyEnabled ? (
                                            <CopyClipboardWrapper
                                                copyText={copyValue as string}
                                            >
                                                <Text>{value}</Text>
                                            </CopyClipboardWrapper>
                                        ) : (
                                            value
                                        )}
                                    </Td>
                                )
                            )}
                        </Tr>
                    </Tbody>
                </>
            ))}
        </Table>
    );
};
