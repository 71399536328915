import React, { forwardRef, useMemo } from 'react';
import { Link } from 'gatsby';
import {
    AspectRatio,
    Box,
    Flex,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import { GenverseTokenType } from '../../../../../../types';
import NFTImage from '../../../general/NFTImage';
import getTokenPK from '../../../../utils/getTokenPK';
import { BiChevronDown } from 'react-icons/bi';

export default forwardRef(
    (
        {
            ensName,
            address,
            primaryToken,
            ...props
        }: {
            ensName: string;
            address: string;
            primaryToken: GenverseTokenType;
        },
        ref
    ) => {
        const [isMobile] = useMediaQuery('(max-width: 768px)');

        const LinkWrapper = useMemo(
            () =>
                ({ children }: { children: React.ReactNode }) =>
                    isMobile ? (
                        <>{children}</>
                    ) : (
                        <Link
                            to={
                                !!ensName?.length || !!address?.length
                                    ? `/page/${ensName || address}`
                                    : ''
                            }
                        >
                            {children}
                        </Link>
                    ),
            [ensName, address, isMobile]
        );

        return (
            <Flex
                key={`${getTokenPK(primaryToken)}`}
                // @ts-ignore
                ref={ref}
                {...props}
                cursor="pointer"
                align="center"
                justify="center"
            >
                <LinkWrapper>
                    <AspectRatio
                        w={['3.5rem', '3.5rem']}
                        ratio={1}
                        marginRight=".5rem"
                        overflow="hidden"
                    >
                        <Box
                            width="100%"
                            height="100%"
                            cursor="pointer"
                            _hover={{
                                opacity: 0.6,
                            }}
                            position="relative"
                        >
                            <NFTImage
                                borderRadius={`50%`}
                                token={primaryToken as GenverseTokenType}
                                alt={ensName}
                            />
                        </Box>
                    </AspectRatio>
                </LinkWrapper>
                <BiChevronDown color={useColorModeValue('black', 'white')} />
            </Flex>
        );
    }
);
