import React from 'react';
import { navigate } from 'gatsby';
import { Button } from '@chakra-ui/react';

export default ({ text = 'Connect' }: { text?: string }) => {
    return (
        <Button bg="primary" color="white" onClick={() => navigate('/connect')}>
            {text}
        </Button>
    );
};
