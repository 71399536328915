import { Box } from '@chakra-ui/react';
import * as React from 'react';

export default ({
    largeContainer,
    extraLargeContainer,
    noContainer,
    children,
    margin,
    ...restProps
}: {
    largeContainer?: boolean;
    extraLargeContainer?: boolean;
    noContainer?: boolean;
    children: any;
    margin?: any;
    restProps?: any;
}) => (
    <Box
        w={
            noContainer
                ? '100vw'
                : [
                      '100vw',
                      largeContainer
                          ? '70vw'
                          : extraLargeContainer
                          ? '80vw'
                          : '65vw',
                  ]
        }
        margin={margin}
        {...restProps}
    >
        {children}
    </Box>
);
