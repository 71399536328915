// @ts-ignore
import ENS, { getEnsAddress } from '@ensdomains/ensjs';

const ensCacheMap = {
    addressMap2Ens: {},
    ensNameMap2Address: {},
} as {
    addressMap2Ens: any;
    ensNameMap2Address: any;
};

export default async (toResolve: string) => {
    if (!toResolve || typeof window === 'undefined' || !global?.window)
        return {};

    const { Web3Client } = require('../../api/Web3');

    const Web3 = Web3Client();

    let ensName, address;

    const isAddress =
        typeof window !== 'undefined' &&
        Web3.utils.isAddress(toResolve?.toLowerCase());

    if (isAddress) address = toResolve?.toLowerCase();
    if (!isAddress) ensName = toResolve;

    // Get cache value resolution
    if (isAddress && !!ensCacheMap?.addressMap2Ens?.[address as string]) {
        return {
            address,
            ensName: ensCacheMap.addressMap2Ens[address as string],
        };
    }

    // Get cache value resolution
    if (!isAddress && !!ensCacheMap?.ensNameMap2Address?.[ensName as string]) {
        return {
            ensName,
            address: ensCacheMap.ensNameMap2Address[ensName as string],
        };
    }

    // If no mapping in cache then resolve via ENS using speedy node websocket

    const ens = new ENS({
        provider: Web3.currentProvider,
        ensAddress: getEnsAddress('1'),
    });

    // If wallet address provided e.g (0x1234)
    if (isAddress) {
        let foundEnsName = (await ens.getName(address))?.name;

        // Check to be sure the reverse record is correct. skip check if the name is null
        if (
            foundEnsName == null ||
            toResolve.toLowerCase() !=
                (await ens.name(foundEnsName).getAddress())?.toLowerCase()
        ) {
            foundEnsName = null;
        }

        address = toResolve?.toLowerCase();
        ensName = foundEnsName;
    }

    // If ENS name provided, resolve the address
    if (!isAddress) {
        address = (await ens.name(toResolve).getAddress())?.toLowerCase();
        ensName = toResolve;
    }

    // Cache values in  global scope for basic performance boost
    ensCacheMap.addressMap2Ens[address] = ensName;
    ensCacheMap.ensNameMap2Address[ensName] = address;

    return {
        ensName,
        address,
    };
};
