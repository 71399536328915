import React from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import {
    Button,
    Flex,
    Image,
    useColorMode,
    useColorModeValue,
    useMediaQuery,
    Text,
} from '@chakra-ui/react';
import { useMoralis } from 'react-moralis';
import useENSName from '../../../hooks/useENSName';
import shortenAddress from '../../../utils/shortenAddress';
import SearchBar from '../../general/SearchBar';
import useGenversePage from '../../../hooks/useGenversePage';
import WalletConnectButton from '../../general/WalletConnectButton';
import AvatarButton from './components/AvatarButton';
import G3LogoDark from '../../../../assets/g3-dark-logo.svg';
import G3LogoLight from '../../../../assets/g3-light-logo.svg';
import G3DropdownMenu from '../../general/G3DropdownMenu';
import { BsFillMoonStarsFill, BsFillSunFill } from 'react-icons/bs';

const Header = ({
    siteTitle,
    siteDescription,
    showNavBarLogo,
    showSearchBar,
    showNavLinks,
    showConnectButton,
}: {
    siteTitle: string;
    siteDescription: string;
    showNavBarLogo?: boolean;
    showNavLinks?: boolean;
    showSearchBar?: boolean;
    showConnectButton?: boolean;
}) => {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const {
        // Moralis,
        isAuthenticated,
        user,
        logout,
    } = useMoralis();

    const ethAddress = user?.get('ethAddress');
    const { ensName, address } = useENSName(ethAddress);

    const { genversePage } = useGenversePage({ address });
    const { toggleColorMode } = useColorMode();

    const filteredMenuItems = [
        {
            label: 'Dashboard',
            onClick: () => navigate(`/dashboard`),
            show: false,
        },
        {
            label: 'MetaPage',
            onClick: () =>
                (!!ensName?.length || !!address?.length) &&
                navigate(`/page/${ensName || address}`),
            show: false,
        },
        {
            label: `${useColorModeValue('Dark', 'Light')} Mode`,
            icon: useColorModeValue(<BsFillMoonStarsFill />, <BsFillSunFill />),
            onClick: toggleColorMode,
            show: true,
        },
        {
            label: 'Logout',
            onClick: async () => {
                await logout();
                navigate('/');
            },
            show: true,
        },
    ]?.filter(({ show }) => !!show);

    const filteredNavbarItems = [
        {
            label: 'Discover',
            onClick: () => navigate(`/`),
            show: true,
        },
        {
            label: 'Dashboard',
            onClick: () => navigate(`/dashboard`),
            show: true,
            requiresAuthentication: true,
            buttonVariant: 'ghost',
        },
        {
            label: 'MetaPage',
            onClick: () =>
                (!!ensName?.length || !!address?.length) &&
                navigate(`/page/${ensName || address}`),
            show: true,
            requiresAuthentication: true,
            buttonVariant: 'ghost',
        },
    ]?.filter(({ show }) => !!show);

    const logoImage = useColorModeValue(G3LogoLight, G3LogoDark);

    const UnauthenticatedLightDarkMode = () => (
        <Flex
            cursor="pointer"
            onClick={toggleColorMode}
            align="center"
            marginRight="1rem"
        >
            {useColorModeValue(<BsFillMoonStarsFill />, <BsFillSunFill />)}
        </Flex>
    );

    return (
        <>
            {/*<BetaBanner />*/}
            <Flex p="8" direction={['column', 'row']} justify={'space-between'}>
                {showNavBarLogo && (
                    <Flex
                        justify="center"
                        align={['center', 'flex-start']}
                        direction="column"
                    >
                        <Link to="/">
                            <Image src={logoImage} width={'12rem'} />
                        </Link>
                        <Text opacity="0.5" marginTop="0.5rem">
                            ALPHA Version
                        </Text>
                    </Flex>
                )}
                <Flex
                    direction={['column', 'row']}
                    gap={['2rem', '1rem']}
                    // background="red"
                    flex={1}
                    justify={
                        showNavLinks !== showSearchBar
                            ? ['center', 'flex-start']
                            : 'space-evenly'
                    }
                >
                    {showNavLinks && (
                        <Flex
                            justify="center"
                            wrap="wrap"
                            align="center"
                            gap={['.5rem', '1rem']}
                            marginTop={['2rem', 0]}
                        >
                            {filteredNavbarItems?.map(
                                ({
                                    label,
                                    onClick,
                                    requiresAuthentication,
                                    buttonVariant,
                                }) => (
                                    <Button
                                        size={isMobile ? 'sm' : 'md'}
                                        {...(buttonVariant
                                            ? { variant: buttonVariant }
                                            : {
                                                  background: '#AB2FB7',
                                                  color: 'white',
                                              })}
                                        onClick={
                                            requiresAuthentication
                                                ? isAuthenticated
                                                    ? onClick
                                                    : () => navigate('/connect')
                                                : onClick
                                        }
                                    >
                                        {label}
                                    </Button>
                                )
                            )}
                        </Flex>
                    )}
                    {showSearchBar && (
                        <Flex align="center" width={['100%', '30vw']}>
                            <SearchBar />
                        </Flex>
                    )}
                </Flex>
                <Flex
                    marginTop={['1rem', 0]}
                    align="center"
                    justify={['center', 'flex-end']}
                >
                    {isAuthenticated && (
                        <Flex align="center">
                            <Flex flex={1}>
                                {((ensName || '')?.length > 0 ||
                                    shortenAddress(address)?.length > 0) && (
                                    <Flex
                                        flex={1}
                                        align="center"
                                        justify="flex-end"
                                        marginRight="1rem"
                                    >{`Welcome back, ${
                                        ensName || shortenAddress(address)
                                    }!`}</Flex>
                                )}
                            </Flex>
                            <G3DropdownMenu
                                menuItems={filteredMenuItems}
                                menuButtonProps={{
                                    as: AvatarButton as any,
                                    ...{
                                        ensName,
                                        address,
                                        primaryToken:
                                            genversePage?.page?.primaryToken,
                                    },
                                }}
                            />
                        </Flex>
                    )}

                    {!isAuthenticated && showConnectButton && (
                        <Flex align="center">
                            <UnauthenticatedLightDarkMode />
                            <WalletConnectButton />
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
    siteDescription: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
};

export default Header;
