import { useQuery, WatchQueryFetchPolicy } from '@apollo/react-hooks';
import GetContractQuery from '../../api/getContract.query.gql';
import { GenverseContractType } from '../../../types';
// import { keys } from 'lodash';

export default ({
    chain,
    contractAddress,
    fetchPolicy = 'cache-and-network',
}: {
    chain: string;
    contractAddress: string;
    fetchPolicy?: WatchQueryFetchPolicy;
}) => {
    const { data, loading, error } = useQuery(GetContractQuery, {
        variables: {
            chain,
            contractAddress,
        },
        skip: !chain || !contractAddress,
        fetchPolicy,
    });

    const genverseContract = !loading && data?.getGenverseContract?.data;

    return {
        genverseContract,
        loading,
        error,
    } as {
        genverseContract: GenverseContractType;
        loading: boolean;
        error: any;
    };
};
