import { GenverseTokenType } from '../../../types';

export default (token: GenverseTokenType, width?: number) => {
    const video = token?.metadata?.animationUrl;

    if (video) {
        const assetWidth = width || 500;
        return `${process.env.GATSBY_MEDIA_CDN_BASE_URL}/a9ee763f-e6db-43cb-81a4-26e4bd0a573e/${assetWidth}/${btoa(
            video
        )}`;
    }

    return video;
};
