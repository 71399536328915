import GREEN_GLOW_BG from '../../assets/GREEN_GLOW_BG.png';
import PURPLE_GLOW_BG from '../../assets/PURPLE_GLOW_BG.png';
import RED_GLOW_BG from '../../assets/RED_GLOW_BG.png';
import BLUE_GLOW_BG from '../../assets/BLUE_GLOW_BG.png';
import YELLOW_GLOW_BG from '../../assets/YELLOW_GLOW_BG.png';
import CYAN_GLOW_BG from '../../assets/CYAN_GLOW_BG.png';
import ORANGE_GLOW_BG from '../../assets/ORANGE_GLOW_BG.png';

export const GLOW_BACKGROUNDS = {
    V1_PURPLE: {
        colour: '#31298A',
        image: PURPLE_GLOW_BG,
    },
    V1_GREEN: {
        colour: '#01ab29',
        image: GREEN_GLOW_BG,
    },
    V1_RED: {
        colour: '#ab0101',
        image: RED_GLOW_BG,
    },
    V1_BLUE: {
        colour: '#011dab',
        image: BLUE_GLOW_BG,
    },
    V1_YELLOW: {
        colour: '#a5ab01',
        image: YELLOW_GLOW_BG,
    },
    V1_CYAN: {
        colour: '#01aba9',
        image: CYAN_GLOW_BG,
    },
    V1_ORANGE: {
        colour: '#ab6501',
        image: ORANGE_GLOW_BG,
    },
};
